import cloneDeep from 'lodash/cloneDeep';
import store from '@app-js/shared/store/index';
import { httpClient } from '@skello-utils/clients';
import { svcRequestsClient } from '../../utils/clients/svc_requests_client';

const initialState = {
  error: null,
  leaveRequests: [],
  availabilityRequests: [],
  availabilityUsers: [],
  managers: [],
  managedEmployees: [],
  shopTeams: [],
  isFetchingLeaveRequests: false,
  isFetchingAvailabilityRequests: false,
  pagination: {
    current_page: 1,
    per_page: 50,
    total_pages: 0,
  },
  availabilitiesPagination: {
    current_page: 1,
    per_page: 50,
    total_pages: 0,
  },
  sort: { column: 'status', order: 'asc' },
  tableLeaveRequestFilters: null,
  tableAvailabilityRequestsFilters: null,
  areEmployeesLoading: false,
  areTeamsLoading: false,
  availabilityRequestsTableHeaders: {
    own: {
      type: { active: true },
      recurrence: { active: true },
      creation_date: { active: true },
      date: { active: true },
      hours: { active: true },
      status: { active: true },
      actions: { active: true },
    },
    teams: {
      employee: { active: true },
      type: { active: true },
      recurrence: { active: true },
      creation_date: { active: true },
      date: { active: true },
      hours: { active: true },
      actions: { active: true },
      status: { active: true },
    },
  },
  leaveRequestsTableHeaders: {
    own: {
      duration: { active: true },
      dates: { active: true, sort: true, name: 'dates' },
      type: { active: true },
      validated_by: { active: true },
      sent_at: { active: true, sort: true, name: 'sent_at' },
      status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
      actions: { active: true },
    },
    teams: {
      employee: { active: true, sort: true, name: 'employee' },
      duration: { active: true },
      dates: { active: true, sort: true, name: 'dates' },
      type: { active: true },
      validated_by: { active: true },
      sent_at: { active: true, sort: true, name: 'sent_at' },
      status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
      actions: { active: true },
    },
  },
  pendingRequestCounts: {
    pendingLeaveRequestsCount: 0,
    pendingAvailabilitiesCount: 0,
    total: 0,
  },
};

const mutations = {
  deleteLeaveRequestSuccess(state, requestId) {
    const requestIndex = state.leaveRequests.findIndex(
      ({ attributes }) => attributes.id === requestId,
    );

    if (requestIndex === -1) return;

    state.leaveRequests.splice(requestIndex, 1);
  },
  fetchLeaveRequestsSuccess(state, payload) {
    state.leaveRequests = payload;
  },
  fetchManagersSuccess(state, payload) {
    state.managers = payload.managers;
  },
  fetchShopTeamsSuccess(state, payload) {
    state.shopTeams = payload.data;
  },
  fetchAvailabilityRequestsSuccess(state, payload) {
    state.availabilityRequests = payload;
  },
  setIsFetchingAvailabilityRequests(state, isFetchingAvailabilityRequests) {
    state.isFetchingAvailabilityRequests = isFetchingAvailabilityRequests;
  },
  setAvailabilitiesPagination(state, pagination) {
    state.availabilitiesPagination = pagination;
  },
  setAvailabilityUsers(state, payload) {
    state.availabilityUsers = payload;
  },
  replaceAvailability(state, newAvail) {
    const availability = state.availabilityRequests
      .find(availabilityRequest => availabilityRequest.id === newAvail?.id);
    if (availability) {
      Object.assign(availability, cloneDeep(newAvail));
    }
  },
  removeAvailability(state, availabilityId) {
    // eslint-disable-next-line arrow-body-style
    state.availabilityRequests = state.availabilityRequests.filter(availabilityRequest => {
      return availabilityRequest.id !== availabilityId;
    });
  },
  setSort(state, sort) {
    state.sort = sort;
  },
  fetchPendingLeaveRequestsCountSuccess(state, pendingRequestCounts) {
    state.pendingRequestCounts.pendingLeaveRequestsCount = pendingRequestCounts;
    state.pendingRequestCounts.total += state.pendingRequestCounts.pendingLeaveRequestsCount;
  },
  fetchPendingAvailabilitiesRequestsCountSuccess(state, pendingRequestCounts) {
    state.pendingRequestCounts.pendingAvailabilitiesCount =
      pendingRequestCounts.pendingAvailabilitiesCount;
    state.pendingRequestCounts.total += state.pendingRequestCounts.pendingAvailabilitiesCount;
  },
  fetchManagedEmployeesSuccess(state, payload) {
    state.managedEmployees = payload.data;
  },
  requestError(state, error) {
    state.error = error;
  },
  setIsFetchingLeaveRequests(state, isFetchingLeaveRequests) {
    state.isFetchingLeaveRequests = isFetchingLeaveRequests;
  },
  setIsFetchingManagedEmployees(state, isFetchingManagedEmployees) {
    state.areEmployeesLoading = isFetchingManagedEmployees;
  },
  setIsFetchingTeams(state, isFetchingTeams) {
    state.areTeamsLoading = isFetchingTeams;
  },
  setIsFetchingPendingRequests(state) {
    state.pendingRequestCounts = {
      pendingLeaveRequestsCount: 0,
      pendingAvailabilitiesCount: 0,
      total: 0,
    };
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setFilters(state, filters) {
    if (store.getters['requests/isAvailabilityRequestsView']) {
      state.tableAvailabilityRequestsFilters = {
        ...state.tableAvailabilityRequestsFilters,
        ...filters,
      };
    } else if (store.getters['requests/isLeaveRequestsView']) {
      state.tableLeaveRequestFilters = { ...state.tableLeaveRequestFilters, ...filters };
    }
  },
};

const actions = {
  async deleteLeaveRequest({ commit }, params) {
    try {
      let response;
      if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.deleteLeaveRequest(params.attributes.id);
      } else {
        response = await httpClient.delete(`/v3/api/leave_requests/${params.attributes.id}`, { params });
      }
      commit('deleteLeaveRequestSuccess', params.attributes.id);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchLeaveRequests({ dispatch }, {
    isOwnRequestsView,
    shopId,
    userId,
    pagination,
    sort,
    filters,
  }) {
    // Endpoint cannot handle all shops now
    if (shopId === 'all') return;

    if (isOwnRequestsView) {
      dispatch('fetchLeaveRequestsOwn', { shopId, userId, pagination, sort, filters });
    } else {
      if (filters.teams.length > 0 && store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        const teamsUsersResponse = (await httpClient.get('/v3/api/teams/user_ids', { params: { shop_id: shopId, teams_id: filters.teams } }));
        const teamUserIds = teamsUsersResponse.data.user_ids.map(item => `${item}`);
        filters.employeeId.push(...teamUserIds);
      }
      dispatch('fetchLeaveRequestsTeam', { shopId, pagination, sort, filters });
    }
  },
  async fetchLeaveRequestsOwn({ commit }, { shopId, userId, pagination, sort, filters }) {
    let params;
    if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
      params = {
        shopIds: JSON.stringify([shopId]),
        userId,
        currentPage: pagination.current_page,
        perPage: pagination.per_page,
        sortBy: sort.column,
        sortOrder: sort.order.toUpperCase(),
        ...(filters.status.length > 0 && { statusFilters: JSON.stringify(filters.status) }),
        ...(filters.absenceTypeId.length > 0 && {
          positionFilters: JSON.stringify(filters.absenceTypeId),
        }),
      };
    } else {
      params = {
        shop_id: shopId,
        user_id: userId,
        current_page: pagination.current_page,
        per_page: pagination.per_page,
        sort_by: sort.column,
        sort_order: sort.order,
        status_filters: filters.status,
        poste_filters: filters.absenceTypeId,
      };
    }

    commit('setIsFetchingLeaveRequests', true);
    try {
      let response;
      let leaveRequests;
      if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.getAllLeaveRequests({ ...params });
        leaveRequests = response.data.map(item => ({ attributes: item }));
        pagination = {
          current_page: params.currentPage,
          per_page: params.perPage,
          total_pages: Math.ceil(response.totalCount / params.perPage),
          total_count: response.totalCount,
        };
      } else {
        response = await httpClient.get('/v3/api/leave_requests', { params });
        pagination = JSON.parse(response.headers['x-pagination']);
        leaveRequests = response.data.data;
      }

      commit('fetchLeaveRequestsSuccess', leaveRequests);
      commit('setPagination', pagination);
      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingLeaveRequests', false);
    }
  },
  async fetchLeaveRequestsTeam({ commit }, { shopId, pagination, sort, filters }) {
    let params;
    if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
      params = {
        shopIds: JSON.stringify([shopId]),
        currentPage: pagination.current_page,
        perPage: pagination.per_page,
        sortBy: sort.column,
        sortOrder: sort.order.toUpperCase(),
        ...(filters.employeeId.length > 0 && {
          employeeFilters: JSON.stringify(filters.employeeId),
        }),
        ...(filters.status.length > 0 && { statusFilters: JSON.stringify(filters.status) }),
        ...(filters.absenceTypeId.length > 0 && {
          positionFilters: JSON.stringify(filters.absenceTypeId),
        }),
      };
    } else {
      params = {
        shop_id: shopId,
        current_page: pagination.current_page,
        per_page: pagination.per_page,
        sort_by: sort.column,
        sort_order: sort.order,
        employee_filters: filters.employeeId,
        status_filters: filters.status,
        poste_filters: filters.absenceTypeId,
        teams_filters: filters.teams,
      };
    }

    commit('setIsFetchingLeaveRequests', true);
    try {
      let response;
      let leaveRequests;
      if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.getAllLeaveRequests({ ...params });
        leaveRequests = response.data.map(item => ({ attributes: item }));
        pagination = {
          current_page: params.currentPage,
          per_page: params.perPage,
          total_pages: Math.ceil(response.totalCount / params.perPage),
          total_count: response.totalCount,
        };
      } else {
        response = await httpClient.get('/v3/api/leave_requests', { params });
        leaveRequests = response.data.data;
        pagination = JSON.parse(response.headers['x-pagination']);
      }

      commit('fetchLeaveRequestsSuccess', leaveRequests);
      commit('setPagination', pagination);
      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingLeaveRequests', false);
    }
  },
  async fetchManagers({ commit }, shopId) {
    try {
      const response = await httpClient.get(`/shops/${shopId}/managers`);
      commit('fetchManagersSuccess', response.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchManagedEmployees({ commit }, { shopId }) {
    try {
      commit('setIsFetchingManagedEmployees', true);
      const params = { shop_id: shopId };
      const response = await httpClient.get('/v3/api/leave_requests/managed_users', { params });
      commit('fetchManagedEmployeesSuccess', response.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingManagedEmployees', false);
    }
  },
  async fetchShopTeams({ commit }, shopId) {
    try {
      commit('setIsFetchingTeams', true);
      const response = await httpClient.get('/v3/api/teams', { params: { shop_id: shopId } });
      commit('fetchShopTeamsSuccess', response.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingTeams', false);
    }
  },
  async createLeaveRequest({ commit }, leaveRequestParams) {
    try {
      let response;
      if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.createLeaveRequest(leaveRequestParams);
      } else {
        response = await httpClient.post('/v3/api/leave_requests', leaveRequestParams);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchPendingRequestsCount({ commit }, params) {
    commit('setIsFetchingPendingRequests');
    try {
      const response = await httpClient.get('/v3/api/pending_requests', { params });
      commit('fetchPendingAvailabilitiesRequestsCountSuccess', response.data);

      if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        const currentUserId = store.state.currentUser.currentUser.id;
        const svcResponse = await svcRequestsClient.getAllLeaveRequests({
          shopIds: JSON.stringify([params.shop_id]),
          receiverId: String(currentUserId),
          statusFilters: JSON.stringify(['pending']),
          skipPagination: true,
        });
        commit('fetchPendingLeaveRequestsCountSuccess', svcResponse.totalCount);
      } else {
        commit('fetchPendingLeaveRequestsCountSuccess', response.data);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async acceptLeaveRequest({ commit }, { leaveRequestId, leaveRequestParams }) {
    try {
      let response;
      if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        const validateUnlockedDays = await httpClient.get('/v3/api/weekly_options/validate_unlocked_days',
          {
            params: {
              shop_id: store.state.currentShop.currentShop.id,
              start_date: leaveRequestParams.startsAt,
              end_date: leaveRequestParams.endsAt,
            },
          });

        if (!validateUnlockedDays.data.areDaysUnlocked) {
          throw new Error('days_locked');
        }
        response = await svcRequestsClient.updateLeaveRequest(leaveRequestId, leaveRequestParams);
      } else {
        response = await httpClient.patch(`/received_leave_requests/${leaveRequestId}`, leaveRequestParams);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async refuseLeaveRequest({ commit }, { leaveRequestId, leaveRequestParams }) {
    try {
      let response;
      if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        response = await svcRequestsClient.updateLeaveRequest(leaveRequestId, leaveRequestParams);
      } else {
        response = await httpClient.patch(`/v3/api/leave_requests/${leaveRequestId}`, leaveRequestParams);
      }

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async fetchAvailabilityRequests({ dispatch },
    {
      isOwnRequestsView,
      shopId,
      userId,
      pagination,
      sort,
      filters,
    },
  ) {
    if (shopId === 'all') throw new Error('Cannot fetch availability requests for all shops');
    if (isOwnRequestsView) {
      dispatch('fetchAvailabilityRequestsOwn', { shopId, userId, pagination, filters });
    } else {
      dispatch('fetchAvailabilityRequestsTeam', { shopId, pagination, sort, filters });
    }
  },
  async fetchAvailabilityRequestsOwn({ commit }, { userId, pagination, filters }) {
    // here we don't use the shop_id because we want ALL the availabilities in ALL shops for the user
    const params = {
      user_id: userId,
      status_order: 'asc',
      use_serializer: true,
      include_users: true,
      status_filters: filters?.status ?? [],
      type_filters: filters?.availabilityType ?? [],
    };

    if (pagination) {
      params.current_page = pagination.current_page;
      params.per_page = pagination.per_page;
    } else {
      params.skip_pagination = true;
    }

    try {
      commit('setIsFetchingAvailabilityRequests', true);

      const response = await httpClient
        .get('/v3/api/availabilities', { params });

      commit('fetchAvailabilityRequestsSuccess', response.data.availabilities.data);
      commit('setAvailabilityUsers', response.data.availabilities.included);

      if (pagination) {
        commit('setAvailabilitiesPagination', response.data.meta_options.pagination);
      }

      // fetchAvailabilityRequestsOwn is used from the EmployeeAvailabilitiesModal
      // This modal has its own store so it needs the availabilities we just fetched
      store.dispatch('employeeAvailabilities/setAvailabilities', response.data.availabilities);
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    } finally {
      commit('setIsFetchingAvailabilityRequests', false);
    }
  },
  async fetchAvailabilityRequestsTeam({ commit }, { shopId, pagination, sort, filters }) {
    const params = {
      shop_id: shopId,
      current_page: pagination.current_page,
      per_page: pagination.per_page,
      status_order: 'asc',
      use_serializer: true,
      include_users: true,
      sort_by: sort.column,
      sort_order: sort.order,
      employee_filters: filters.employeeId,
      status_filters: filters.status,
      poste_filters: filters.absenceTypeId,
      team_filters: filters.teams,
      type_filters: filters.availabilityType,
    };

    try {
      commit('setIsFetchingAvailabilityRequests', true);

      const response = await httpClient.get('/v3/api/availabilities', { params });
      commit('fetchAvailabilityRequestsSuccess', response.data.availabilities.data);
      commit('setAvailabilityUsers', response.data.availabilities.included);
      commit('setAvailabilitiesPagination', response.data.meta_options.pagination);

      return response;
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    } finally {
      commit('setIsFetchingAvailabilityRequests', false);
    }
  },
  async updateAvailabilityRequest({ commit }, { availability }) {
    try {
      // Same endpoint used than the availabilities modale in the employees space
      const endpoint = `/v3/api/users/${availability.attributes.userId}/availabilities/bulk_update`;

      const params = {
        shop_id: availability.attributes.shopId,
        availabilities: [{
          id: availability.id,
          recurrence: availability.attributes.recurrence,
          day_of_week: availability.attributes.dayOfWeek,
          ends_at: availability.attributes.endsAt,
          starts_at: availability.attributes.startsAt,
          status: availability.attributes.status,
          user_id: availability.attributes.userId,
          shop_id: availability.attributes.shopId,
          request_status: availability.attributes.requestStatus,
          _destroy: false,
        }],
      };

      const response = await httpClient.patch(endpoint, params);
      const updatedAvailability = response.data.data.find(availabilityResponse => (
        availabilityResponse.id === availability.id
      ));
      commit('replaceAvailability', updatedAvailability);
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    }
  },
  async destroyAvailabilityRequest({ commit }, { availability }) {
    try {
      // Same endpoint used than the availabilities modale in the employees space
      // It can be used to destroy ressources as well with _destroy: true
      const endpoint = `/v3/api/users/${availability.attributes.userId}/availabilities/bulk_update`;

      const params = {
        availabilities: [{
          id: availability.id,
          shop_id: availability.attributes.shopId,
          _destroy: true,
        }],
      };

      await httpClient.patch(endpoint, params);
      commit('removeAvailability', availability.id);
    } catch (error) {
      commit('requestError', error.response);
      throw error;
    }
  },
};

const getters = {
  canAccessLeaveRequests: () => (
    store.getters['currentOrganisation/checkPackOfferFlag']('leave_requests_enabled') &&
    (
      store.getters['currentLicense/isCurrentUserSystemAdmin'] ||
      store.getters['currentLicense/canCreateSelfLeaveRequests'] ||
      store.getters['currentLicense/canManageEmployeeRequests']
    )
  ),
  canAccessAvailabilityRequests: () => (
    store.getters['currentLicense/isCurrentUserSystemAdmin'] ||
      store.getters['currentLicense/canCreateSelfAvailabilities'] ||
      store.getters['currentLicense/canManageEmployeeRequests']
  ),
  isLeaveRequestsView: (_state, _getters, rootState) => rootState.route.name.includes('leave_requests'),
  isAvailabilityRequestsView: (_state, _getters, rootState) => rootState.route.name.includes('availability_requests'),
  tableFilters: (state, _getters) => {
    if (_getters.isLeaveRequestsView) {
      return state.tableLeaveRequestFilters;
    }
    if (_getters.isAvailabilityRequestsView) {
      return state.tableAvailabilityRequestsFilters;
    }
    return null;
  },
  areRequestsLoading: state => (
    state.isFetchingLeaveRequests ||
    state.isFetchingAvailabilityRequests
  ),
  areFiltersActive: (_, selfGetters) => {
    if (!selfGetters.tableFilters) return false;

    return Object.values(selfGetters.tableFilters).some(filter => filter.length > 0);
  },
  decoratedPendingRequests: state => (state.pendingRequestCounts.total > 99 ? '99+' : `${state.pendingRequestCounts.total}`),
  totalPendingLeaveRequests: state => state.pendingRequestCounts.pendingLeaveRequestsCount,
  totalPendingAvailabilityRequests: state => state.pendingRequestCounts.pendingAvailabilitiesCount,
  decoratedPendingLeaveRequests: (_, selfGetters) => (
    selfGetters.totalPendingLeaveRequests > 99 ? '99+' : `${selfGetters.totalPendingLeaveRequests}`
  ),
  decoratedPendingAvailabilityRequests: (_, selfGetters) => (
    selfGetters.totalPendingAvailabilityRequests > 99 ? '99+' : `${selfGetters.totalPendingAvailabilityRequests}`
  ),
  ownDefaultFiltersLeaveRequests: () => ({
    status: [],
    absenceTypeId: [],
    availabilityType: [],
  }),
  teamDefaultFiltersLeaveRequests: () => ({
    status: [],
    absenceTypeId: [],
    employeeId: [],
    teams: [],
    availabilityType: [],
  }),
  areFiltersLoading: state => state.areEmployeesLoading || state.areTeamsLoading,
  leaveRequestsTableHeaders: () => {
    const useSvcRequests = store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1');
    const validatorHeader = useSvcRequests ?
      { validator: { active: true } } :
      { validated_by: { active: true } };

    return {
      own: {
        duration: { active: true },
        dates: { active: true, sort: true, name: 'dates' },
        type: { active: true },
        ...validatorHeader,
        sent_at: { active: true, sort: true, name: 'sent_at' },
        status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
        actions: { active: true },
      },
      teams: {
        employee: { active: true, sort: true, name: 'employee' },
        duration: { active: true },
        dates: { active: true, sort: true, name: 'dates' },
        type: { active: true },
        ...validatorHeader,
        sent_at: { active: true, sort: true, name: 'sent_at' },
        status: { active: true, sort: true, name: 'status', defaultSort: 'asc' },
        actions: { active: true },
      },
    };
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
