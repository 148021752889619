import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import store from '@app-js/shared/store/index';
import { filterUsers } from '@app-js/shared/utils/filters_helper';
import skDate from '@skello-utils/dates';

const ENDPOINT_NAMESPACE = '/v3/api/plannings';

const initialState = {
  users: [],
  availabilities: [],
  daysWorked: {},
  usersLoading: false,
  availabilitiesLoading: false,
  error: null,
  weeklyTotalDifferenceByUser: {},
};

const matchRelationshipToUsers = (user, included) => {
  const highestLicense = associationMatcherItem(user, included, { key: 'highestLicense', type: 'license' });
  const contract = associationMatcherItem(user, included, { key: 'contract', type: 'contract' });

  Object.assign(user.relationships, { highestLicense, contract });

  return user;
};

const mutations = {
  setPlanningUsers(state, { users, overwriteStore = true }) {
    const parsedUsers = users.data.map(user => matchRelationshipToUsers(user, users.included));

    if (overwriteStore) {
      state.users = parsedUsers;
      return;
    }

    state.users = [...state.users, ...parsedUsers];
  },
  setAvailabilities(state, { data, overwriteStore = true }) {
    data.forEach(({ attributes }) => {
      // Unlike shifts availabilitites are stored with timezone offset applied but in UTC0.
      // Example: user's browser in GMT+2 creates an availability selecting start at 0h00
      // it will be stored in database as 22h00 of the previous day in UTC0
      // Not using skDate.utc() here is a workaround
      const startsAt = skDate(attributes.startsAt);
      const endsAt = skDate(attributes.endsAt);
      attributes.startTime = startsAt.format('HH:mm');
      attributes.endTime = endsAt.format('HH:mm');
      attributes.dayStr = startsAt.format('YYYY-MM-DD');
    });
    state.availabilities = overwriteStore ? data : [...state.availabilities, ...data];
  },
  setDaysWorked(state, { daysWorked, overwriteStore = true }) {
    state.daysWorked = overwriteStore ? daysWorked : { ...state.daysWorked, ...daysWorked };
  },
  setError(state, error) {
    state.error = error;
  },
  performingRequest(state, key) {
    state[key] = true;
  },
  requestComplete(state, key) {
    state[key] = false;
  },
  setWeeklyTotalDifferenceFor(state, { userId, diff }) {
    Vue.set(state.weeklyTotalDifferenceByUser, userId, diff);
  },
};

const actions = {
  fetchPlanningUsers({ commit }, { params, overwriteStore = true }) {
    commit('performingRequest', 'usersLoading');

    return httpClient
      .get(`${ENDPOINT_NAMESPACE}/users`, { params })
      .then(response => {
        commit('setPlanningUsers', { users: response.data, overwriteStore });
        return response;
      })
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'usersLoading'));
  },
  fetchAvailabilities({ commit }, { params, overwriteStore = true }) {
    commit('performingRequest', 'availabilitiesLoading');

    return httpClient
      .get(`${ENDPOINT_NAMESPACE}/availabilities`, { params })
      .then(response => {
        commit('setAvailabilities', { ...response.data, overwriteStore });
        return response;
      })
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'availabilitiesLoading'));
  },
  sortUsers({ commit }, params) {
    commit('performingRequest', 'usersLoading');

    return httpClient
      .patch('/v3/api/plannings/users/sort_users', params)
      .then(response => response)
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'usersLoading'));
  },
  fetchDayRateUsersDaysWorked({ commit }, { params, overwriteStore = true }) {
    commit('performingRequest');

    return httpClient
      .get(`${ENDPOINT_NAMESPACE}/day_rate_total`, { params })
      .then(response => {
        commit('setDaysWorked', { daysWorked: response.data, overwriteStore });
        return response;
      })
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => commit('requestComplete'));
  },
};

const storeGetters = {
  availabilitiesForUser: state => userId => state.availabilities.filter(availability => (
    availability.attributes.userId === parseInt(userId, 10)
  )),

  getShiftsForFilter: () => {
    let shifts = store.getters['planningsShifts/shiftsForCurrentWeek'];
    if (store.getters['planningsState/isDailyView']) {
      const currentDate = store.getters['planningsState/currentDate'];
      shifts = store.getters['planningsShifts/dayCellShifts'](currentDate, shifts);
    } else if (store.getters['planningsState/isMonthlyView']) {
      shifts = store.getters['planningsShifts/monthlyShifts'];
    }

    return shifts;
  },
  // duplicate of filteredUsers in order to get users with pending leave request
  // but to not impact more than planning displayed user
  // and not other features dependent of the users in planning
  displayedInPlanningUsers: (state, getters) => {
    const { filters } = store.state.planningsState;
    const { users } = state;
    const shifts = getters.getShiftsForFilter;

    return filterUsers(
      filters, users, shifts.concat(store.state.planningsShifts.pendingLeaveRequestShifts),
    );
  },
  filteredUsers: (state, getters) => {
    const { filters } = store.state.planningsState;
    const { users } = state;
    const shifts = getters.getShiftsForFilter;

    return filterUsers(filters, users, shifts);
  },
  dayFilteredUsers: state => date => {
    const { filters } = store.state.planningsState;
    const { users } = state;
    const shifts = store.getters['planningsShifts/shiftsForCurrentPeriod'];
    const dayShifts = store.getters['planningsShifts/dayCellShifts'](date, shifts);

    return filterUsers(filters, users, dayShifts);
  },
  birthdayUsersAtDay: state => day => {
    const date = skDate(day);
    return state.users
      .filter(user => {
        const userBirthday = skDate(user.attributes.birthday);
        return userBirthday.date() === date.date() && userBirthday.month() === date.month();
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: storeGetters,
};
