class Csrf {
  constructor() {
    if (!Csrf.singleton) {
      this.init();
      Csrf.singleton = this;
    }
    return Csrf.singleton;
  }

  init() {
    const tokenEl = document.querySelector('meta[name=csrf-token]');

    if (tokenEl !== null) {
      this.csrfToken = tokenEl.getAttribute('content');
    } else {
      this.csrfToken = null;
    }
  }

  token() {
    return this.csrfToken;
  }

  headerKey() {
    return 'X-CSRF-Token';
  }

  headers() {
    if (this.csrfToken() !== null) {
      return {
        [this.headerKey()]: this.token,
      };
    }
    return {};
  }
}

export default new Csrf();
