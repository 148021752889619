import axios from 'axios';
import { AuthWebInterceptor } from '@skelloapp/skello-auth-client';
import { merge } from '@skello-utils/object';
import { authClient } from './auth_client';
import {
  BaseApiConfig, RailsApiConfig,
} from './constant_client';

const HttpClientConfig = merge(BaseApiConfig, RailsApiConfig);

export const httpClient = axios.create(HttpClientConfig);

export const msHttpClient = axios.create(BaseApiConfig);

export const sendRequestConfig = async config => {
  window.activeVueResources = window.activeVueResources || 0;
  window.activeVueResources += 1;

  const authToken = await authClient.getAuthToken();

  if (authToken) {
    return new AuthWebInterceptor(authClient.authClient, {
      token: authToken.token,
      refreshToken: authToken.refreshToken,
    }).request(config);
  }

  return config;
};

export const successReceivedRequestConfig = async config => {
  window.activeVueResources = window.activeVueResources || 0;
  window.activeVueResources += 1;

  return config;
};

export const errorReceivedRequestConfig = async error => {
  window.activeVueResources -= 1;

  const authToken = await authClient.getAuthToken();

  if (!authToken) throw error;

  await new AuthWebInterceptor(
    authClient.authClient,
    {
      token: authToken.token,
      refreshToken: authToken.refreshToken,
    },
  )
    .responseError(error, async data => {
      await authClient.setAuthToken(data);
      error.config.headers.Authorization = `Bearer ${data.token}`;

      const client = error.config.headers['X-CSRF-Token'] ? httpClient : msHttpClient;

      return client.request(error.config);
    });

  throw error;
};

httpClient.interceptors.request.use(sendRequestConfig);
httpClient.interceptors.response.use(successReceivedRequestConfig, errorReceivedRequestConfig);

msHttpClient.interceptors.request.use(sendRequestConfig);
msHttpClient.interceptors.response.use(successReceivedRequestConfig, errorReceivedRequestConfig);
