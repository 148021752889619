import { svcIntelligenceUrl } from '@config/env';
import { HttpClient } from '@skelloapp/client-http-lib/src/Client/HttpClient';
import { AnalyseDocumentRepository } from '@skelloapp/svc-intelligence-sdk';
import {
  sendRequestConfig,
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
} from './http_client';

class SvcIntelligenceClient {
  constructor() {
    const baseURL = svcIntelligenceUrl;

    const httpClient = new HttpClient(baseURL, {
      clientSource: 'skelloApp:web',
    });

    httpClient.clientWithRetry.interceptors.request.use(sendRequestConfig);
    httpClient.clientWithRetry.interceptors.response.use(
      successReceivedRequestConfig,
      errorReceivedRequestConfig,
    );

    this.analyseDocumentRepository = new AnalyseDocumentRepository(httpClient);
  }

  async requestAnalysisPlanning(documentId, shopId, documentType) {
    await this.analyseDocumentRepository.analyse(
      {
        analysisType: documentType,
        shopId,
      },
      {
        documentId,
      },
    );
  }
}

export const svcIntelligenceClient = new SvcIntelligenceClient();

export default svcIntelligenceClient;
