import { svcDocumentsV2Url } from '@config/env';
import { HttpClient } from '@skelloapp/client-http-lib/src/Client/HttpClient';
import { DocumentRepository } from '@skelloapp/svc-documents-v2-client';
import {
  sendRequestConfig,
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
} from './http_client';

// We set a higher timeout than the default 5000ms because
// The documents upload can take a relatively "long" time
const AXIOS_TIMEOUT = 30000;

const httpClient = new HttpClient(svcDocumentsV2Url, {
  clientSource: 'skelloApp:web',
  timeout: AXIOS_TIMEOUT,
});

httpClient.clientWithRetry.interceptors.request.use(sendRequestConfig);
httpClient.clientWithRetry.interceptors.response.use(
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
);

httpClient.clientWithoutRetry.interceptors.request.use(sendRequestConfig);
httpClient.clientWithoutRetry.interceptors.response.use(
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
);

const svcDocumentV2Client = new DocumentRepository(httpClient);
export default svcDocumentV2Client;
