import * as Sentry from '@sentry/vue';
import store from '@app-js/shared/store/index';
import { httpClient } from '@skello-utils/clients';

const initialState = {
  currentLicense: {
    id: '',
    type: 'license',
    attributes: {
      canArchiveAndRestoreUsers: '',
      canCreateAmendment: '',
      canCreateClusterNode: '',
      canCreateEmployee: '',
      canCreatePosteAndTeam: '',
      canCreateSelfAvailabilities: '',
      canCreateSelfLeaveRequests: '',
      canCreateShiftSwap: '',
      canCreateShifts: '',
      canEditShifts: '',
      canCreateShop: '',
      canEditActivityReport: '',
      canEditBonus: '',
      canEditEmployeeInfo: '',
      canEditEmployeePersonalInfosAndBankData: '',
      canEditWageAndTime: '',
      canEditLegalAlertsConfig: '',
      canEditManagementIndicators: '',
      canEditPunchClockData: '',
      canEditPunchClockSettings: '',
      canEditReportColumns: '',
      canEditReportComment: '',
      canEditSelfHr: '',
      canEditShopBillingData: '',
      canEditShopPermissions: '',
      canEditShopRulesAndAbsences: '',
      canLockPlanning: '',
      canManageDocumentTemplates: '',
      canManageEmployeeRequests: '',
      canPublishPlanning: '',
      canReadAllEmployees: '',
      canManageEmployeeDocuments: '',
      canReadEmployeeInfo: '',
      canReadEmployeePersonalInfosAndBankData: '',
      canReadHoursCounterAndTotal: '',
      canUploadSelfDocuments: '',
      canReadSelfOnly: '',
      canReadPaidVacationCounter: '',
      canReadPunchClockData: '',
      canReadReport: '',
      canReadShopRulesAndAbsences: '',
      canReadTeammates: '',
      canTmplockPlanning: '',
      name: '',
      position: '',
    },
  },
  error: null,
  loading: false,
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  fetchCurrentLicenseSuccess(state, payload) {
    state.currentLicense = payload;
  },

  fetchCurrentLicenseError(state, error) {
    state.error = error;
  },
};

const formatToJwtLicense = license => ({
  originalType: license.attributes.originalType,
  permissions: Object.fromEntries(Object.entries(license.attributes).filter(([key]) => key.startsWith('can'))),
  position: license.attributes.position,
});

const areLicensesEqual = (referenceLicense, comparedLicense) => {
  if (referenceLicense && comparedLicense) {
    return Object.keys(referenceLicense)
      .every(key => {
        if (key === 'permissions') {
          return Object.keys(referenceLicense[key])
            .every(permission => referenceLicense.permissions[permission] ===
              comparedLicense.permissions[permission]);
        }
        return referenceLicense[key] === comparedLicense[key];
      });
  }
  return false;
};

const actions = {
  fetchCurrentLicense({ commit }, clusterNodeId) {
    return httpClient
      .get('/v3/api/current_license', { params: { cluster_node_id: clusterNodeId } })
      .then(response => {
        if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_SENTRY_LICENSE_API_VS_JWT')) {
          const currentJwtLicenseForClusterNode = store.getters['currentUserLicenses/getCurrentUserLicenseForClusterNode'](clusterNodeId);
          const currentLicenseJwtFormatted = formatToJwtLicense(response.data.data);

          if (!areLicensesEqual(currentLicenseJwtFormatted, currentJwtLicenseForClusterNode)) {
            Sentry.captureException(new Error('Licenses are not equal'), {
              contexts: {
                clusterNodeId,
                fromDB: currentLicenseJwtFormatted,
                fromJWT: currentJwtLicenseForClusterNode,
              },
            });
          }
        }

        commit('fetchCurrentLicenseSuccess', response.data.data);
      })
      .catch(error => {
        commit('fetchCurrentLicenseError', error);
        throw error;
      });
  },
};

const getters = {
  canArchiveAndRestoreUsers: state => state.currentLicense.attributes.canArchiveAndRestoreUsers,
  canAccessShopSettingsTab: state => state.currentLicense.attributes.canEditShopRulesAndAbsences ||
    state.currentLicense.attributes.canReadShopRulesAndAbsences ||
    state.currentLicense.attributes.canEditShopPermissions ||
    state.currentLicense.attributes.canEditLegalAlertsConfig ||
    state.currentLicense.attributes.canEditShopBillingData ||
    state.currentLicense.attributes.canCreatePosteAndTeam,
  canEditShopRulesAndAbsences: state => state.currentLicense.attributes.canEditShopRulesAndAbsences,
  canReadShopRulesAndAbsences: state => state.currentLicense.attributes.canReadShopRulesAndAbsences,
  canCreatePosteAndTeam: state => state.currentLicense.attributes.canCreatePosteAndTeam,
  canEditShopBillingData: state => state.currentLicense.attributes.canEditShopBillingData,
  canManageShopRulesAndAbsences: state => (
    state.currentLicense.attributes.canEditShopRulesAndAbsences ||
      state.currentLicense.attributes.canReadShopRulesAndAbsences
  ),
  canEditLegalAlertsConfig: state => state.currentLicense.attributes.canEditLegalAlertsConfig,
  canReadEmployeeInfo: state => state.currentLicense.attributes.canReadEmployeeInfo,
  canReadTeammates: state => state.currentLicense.attributes.canReadTeammates,
  canEditEmployeeInfo: state => state.currentLicense.attributes.canEditEmployeeInfo,
  canEditSelfHr: state => state.currentLicense.attributes.canEditSelfHr,
  canReadPaidVacationCounter: state => state.currentLicense.attributes.canReadPaidVacationCounter,
  canManageEmployeeDocuments: state => state.currentLicense.attributes.canManageEmployeeDocuments,
  // eslint-disable-next-line max-len
  canReadEmployeePersonalInfosAndBankData: state => state.currentLicense.attributes.canReadEmployeePersonalInfosAndBankData,
  // eslint-disable-next-line max-len
  canEditEmployeePersonalInfosAndBankData: state => state.currentLicense.attributes.canEditEmployeePersonalInfosAndBankData,
  canCreateEmployee: state => state.currentLicense.attributes.canCreateEmployee,
  canManageEmployeeRequests: state => state.currentLicense.attributes.canManageEmployeeRequests,
  canCreateSelfLeaveRequests: state => state.currentLicense.attributes.canCreateSelfLeaveRequests,
  canCreateSelfAvailabilities: state => state.currentLicense.attributes.canCreateSelfAvailabilities,
  canEditWageAndTime: state => state.currentLicense.attributes.canEditWageAndTime,
  canManageEmployeeAmendments: state => state.currentLicense.attributes.canCreateAmendment,
  canReadPunchClockData: state => state.currentLicense.attributes.canReadPunchClockData,
  canEditPunchClockSettings: state => state.currentLicense.attributes.canEditPunchClockSettings,
  canManageHris: (state, getter) => getter.canReadHris &&
    state.currentLicense.attributes.canReadEmployeePersonalInfosAndBankData,
  canReadHris: ({ currentLicense }) => {
    const {
      originalType,
      canManageEmployeeDocuments,
      canReadEmployeeInfo,
      canReadTeammates,
      canReadAllEmployees,
    } = currentLicense.attributes;

    const isOnSameTeamThanCurrentUser = store.getters['selectedEmployee/isOnSameTeamThanCurrentUser'];
    const isStrictSubordinateOfCurrentUser = store.getters['selectedEmployee/isStrictSubordinateOfCurrentUser'];
    const isCurrentUser = store.getters['selectedEmployee/isCurrentUser'];

    if (originalType === 'system_admin') return true;
    if (!canManageEmployeeDocuments) return false;
    if (!canReadEmployeeInfo) return false;

    const canReadEmployee = (canReadTeammates && isOnSameTeamThanCurrentUser) ||
      canReadAllEmployees;

    const hierarchicallyAccessible = isStrictSubordinateOfCurrentUser || isCurrentUser;

    return canReadEmployee && hierarchicallyAccessible;
  },
  isSystemAdmin: state => state.currentLicense.attributes.position === 0,
  isEmployee: state => state.currentLicense.attributes.position > 6,
  canOnlyReadHris: (state, getter) => getter.canReadHris &&
    !state.currentLicense.attributes.canReadEmployeePersonalInfosAndBankData,
  canReadHoursCounterAndTotal: state => state.currentLicense.attributes.canReadHoursCounterAndTotal,
  canUploadSelfDocuments: state => state.currentLicense.attributes.canUploadSelfDocuments,
  canEditManagementIndicators: state => state.currentLicense.attributes.canEditManagementIndicators,
  canManageDocumentTemplates: state => state.currentLicense.attributes.canManageDocumentTemplates,
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
