import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import store from '@app-js/shared/store/index';
import {
  sanitizeShift,
  openingAndClosingTimeAt,
  formatVisibleDays,
  filterShiftsForPeriod,
  setShiftsStartsAtForDisplay,
} from '@app-js/plannings/shared/utils/planning_helpers';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';

const MAX_HISTORY_LENGTH = 40;
export const MINIMUM_PIXEL_BY_QUARTER_HOURS = 15;

const initialState = {
  totalRowSelectedItem: 'total',
  displayTotalPeriodTab: false,
  events: [],
  eventsLoading: true,
  eventManagingLoading: false,
  eventDeleteLoading: false,
  holidays: [],
  holidayLoading: true,
  weeklyOptions: {},
  monthlyOptions: [],
  weeklyOptionsLoading: true,
  monthlyOptionsLoading: true,
  shopPlanningConfig: {},
  shopPlanningConfigLoading: true,
  userPlanningConfig: {},
  userPlanningConfigLoading: true,
  shopAlerts: [],
  shopAlertsLoading: true,
  automaticPlanningLoading: false,
  filters: {},
  validateDayLoading: new Array(7).fill(false),
  validatePeriodLoading: false,
  // Always keep unfinishedFeaturesVisible to false. This state hides unfinished features on planning
  unfinishedFeaturesVisible: false,
  error: false,
  shiftDragging: false,
  // `null` is used for unassigned row
  shiftDragAndCreatingRowId: undefined,
  popularShiftDragging: false,
  shiftSelectedDays: null,
  actionsHistory: [],
  actionsHistoryPosition: 0,
  undoRedoLoading: false,
  unlockRequestLoading: false,
  isMaskDisplayed: false,
  absenceSidePanelOpeningOrigin: null,
  weeklyAttendanceSheetSignatures: [],
  weeklyAttendanceSheetSignaturesLoading: true,
  selectedDate: null,
  selectedWeeklyCountersInMonthlyView: {},
  dayViewPlanningSizeVariables: {
    sidebarWidth: 160,
    pixelPerQuarterHours: 15, // pixelPerMinute * MINIMUM_PIXEL_BY_QUARTER_HOURS
    pixelPerMinute: 1,
    countersWidth: 146,
  },
};

const mutations = {
  toggleWeeklyCounterInMonthlyView(state, week) {
    Vue.set(
      state.selectedWeeklyCountersInMonthlyView,
      week,
      !state.selectedWeeklyCountersInMonthlyView[week],
    );
  },
  setShiftDragging(state, newValue) {
    state.shiftDragging = newValue;
  },
  setShiftDragAndCreatingRowId(state, newValue) {
    state.shiftDragAndCreatingRowId = newValue;
  },
  setPopularShiftDragging(state, newValue) {
    state.popularShiftDragging = newValue;
  },

  setSelectedDate(state, date) {
    state.selectedDate = date;
  },

  setDayPlanningSizeVariables(state, pixelPerMinute) {
    const pixelPerQuarterHours = pixelPerMinute * MINIMUM_PIXEL_BY_QUARTER_HOURS;

    Vue.set(state.dayViewPlanningSizeVariables, 'pixelPerMinute', pixelPerMinute);
    Vue.set(state.dayViewPlanningSizeVariables, 'pixelPerQuarterHours', pixelPerQuarterHours);
  },

  setAsSelectedTab(state, item) {
    // Todo disable counter tab
    state.totalRowSelectedItem = item;
  },

  toggleTotalPeriodTab(state) {
    state.displayTotalPeriodTab = !state.displayTotalPeriodTab;
  },
  closeTotalPeriodTab(state) {
    state.displayTotalPeriodTab = false;
  },
  // WeeklyOptions
  setWeeklyOptions(state, payload) {
    state.weeklyOptions = payload.data;
    const monthlyOptions = state.monthlyOptions.map(weeklyOption => {
      if (weeklyOption.date === payload.data.attributes.date) {
        return { ...payload.data.attributes };
      }
      return { ...weeklyOption };
    });

    state.monthlyOptions = monthlyOptions;
  },
  weeklyOptionsComplete(state) {
    state.weeklyOptionsLoading = false;
  },
  weeklyOptionsPending(state) {
    state.weeklyOptionsLoading = true;
  },

  // MonthlyOptions
  setMonthlyOptions(state, payload) {
    state.monthlyOptions = payload.map(monthlyOption => monthlyOption.data.attributes);
  },
  monthlyOptionsComplete(state) {
    state.monthlyOptionsLoading = false;
  },
  monthlyOptionsPending(state) {
    state.monthlyOptionsLoading = true;
  },

  // UserPlanningConfigs
  setUserPlanningConfig(state, payload) {
    state.userPlanningConfig = payload.data;
  },
  userPlanningConfigPending(state) {
    state.userPlanningConfigLoading = true;
  },
  userPlanningConfigSuccess(state) {
    state.userPlanningConfigLoading = false;
  },

  // ShopPlanningSettings
  setShopPlanningConfig(state, payload) {
    state.shopPlanningConfig = payload.data;
  },
  shopPlanningConfigPending(state) {
    state.shopPlanningConfigLoading = true;
  },
  shopPlanningConfigSuccess(state) {
    state.shopPlanningConfigLoading = false;
  },

  // Shop Alerts
  setShopAlerts(state, payload) {
    // TODO : DEV-11565 holidays_increase is not implemented
    state.shopAlerts = payload.data.filter(alert => alert.attributes.name !== 'holidays_increase');
  },
  shopAlertsPending(state) {
    state.shopAlertsLoading = true;
  },
  shopAlertsSuccess(state) {
    state.shopAlertsLoading = false;
  },

  // AutomaticPlanning
  automaticPlanningAssignmentPending(state) {
    state.automaticPlanningLoading = true;
  },
  automaticPlanningAssignmentComplete(state) {
    state.automaticPlanningLoading = false;
  },

  // validateDay
  validateDayPending(state, dayIndex) {
    Vue.set(state.validateDayLoading, dayIndex, true);
  },
  validateDayComplete(state, dayIndex) {
    Vue.set(state.validateDayLoading, dayIndex, false);
  },

  // validateWeek
  validatePeriodPending(state) {
    state.validatePeriodLoading = true;
  },

  validatePeriodComplete(state) {
    state.validatePeriodLoading = false;
  },

  // unlockRequest
  unlockRequestPending(state) {
    state.unlockRequestLoading = true;
  },
  unlockRequestComplete(state) {
    state.unlockRequestLoading = false;
  },

  // Events
  eventsFetchPending(state) {
    state.eventsLoading = true;
  },
  eventsFetchComplete(state) {
    state.eventsLoading = false;
  },
  eventRequestPending(state) {
    state.eventManagingLoading = true;
  },
  eventRequestComplete(state) {
    state.eventManagingLoading = false;
  },
  eventDeletePending(state) {
    state.eventDeleteLoading = true;
  },
  eventDeleteComplete(state) {
    state.eventDeleteLoading = false;
  },
  setEvents(state, payload) {
    state.events = payload.data;
  },
  createEventSuccess(state, payload) {
    const event = payload.data;
    state.events.push(event);
  },
  updateEventSuccess(state, payload) {
    const event = payload.data;

    const index = state.events.findIndex(item => item.id === event.id);

    if (index >= 0) {
      Vue.set(state.events, index, event);
    }
  },
  deleteEventSuccess(state, id) {
    const index = state.events.findIndex(item => item.id === id);
    if (index >= 0) {
      state.events.splice(index, 1);
    }
  },

  // day selection array
  initDaySelectionArray(state, payload) {
    state.shiftSelectedDays = new Array(payload.visibleDays.length).fill({});

    payload.visibleDays.forEach((visibleDay, index) => {
      const selectedStatus = payload.currentDayIndex === index;
      const date = selectedStatus ? null : visibleDay.date;

      Vue.set(state.shiftSelectedDays, index, { selectedStatus, date });
    });
  },

  setDaySelection(state, payload) {
    Vue.set(state.shiftSelectedDays[payload.index], 'selectedStatus', payload.selectedStatus);
  },

  resetDaySelection(state) {
    state.shiftSelectedDays = null;
  },

  // Errors
  setError(state, error) {
    state.error = error;
  },

  // set filters by postes, teams and users for daily and weekly planning
  setFilters(state, filters) {
    state.filters = filters;
  },

  holidayFetchPending(state) {
    state.holidayLoading = true;
  },
  holidayFetchComplete(state) {
    state.holidayLoading = false;
  },
  setHolidays(state, payload) {
    state.holidays = payload;
  },

  // Actions history
  // We save fetched shifts to history when it's a refetch,
  // for exemple after applying a template, that way, we're
  // able to "reverse/replay" the fetch when undoing/redoing
  historyAddFetch(state, payload) {
    // We first scope old and new shifts to the current planning period
    const period = store.getters['planningsState/isMonthlyView'] ? 'month' : 'week';
    const periodDates = store.getters['planningsState/periodDates'](period);
    const startsAt = skDate(periodDates.startsAt).utc(true);
    const endsAt = skDate(periodDates.endsAt).utc(true);

    const oldShifts = filterShiftsForPeriod(store.state.planningsShifts.shifts, startsAt, endsAt);
    // We need to call setShiftsStartsAtForDisplay on new shifts before filtering
    setShiftsStartsAtForDisplay(payload.data);
    const newShifts = filterShiftsForPeriod(payload.data, startsAt, endsAt);

    const oldShiftsIds = oldShifts.map(shift => shift.id);
    const newShiftsIds = newShifts.map(shift => shift.id);
    const addedShifts = newShifts.filter(shift => !oldShiftsIds.includes(shift.id));
    const deletedShifts = oldShifts.filter(shift => (
      !newShiftsIds.includes(shift.id)
    ));

    state.actionsHistory.splice(
      state.actionsHistoryPosition,
      state.actionsHistory.length,
      { action: 'FETCH', addedShifts, deletedShifts },
    );
  },
  historyAddCreate(state, payload) {
    state.actionsHistory.splice(
      state.actionsHistoryPosition,
      state.actionsHistory.length,
      { action: 'CREATE', shifts: cloneDeep(payload.data) },
    );
  },
  historyAddUpdate(state, payload) {
    const shiftsState = store.getters['planningsShifts/shiftsForCurrentPeriod'];
    const shiftToSaveIds = payload.data.map(shift => shift.id);
    // If shift was moved with drag and drop, shift from state has already been changed
    // -> use originalShift added to payload to keep the actual old shift in history
    const oldShifts = payload.originalShifts ?
      payload.originalShifts :
      shiftsState.filter(shift => shiftToSaveIds.includes(shift.id));
    oldShifts.forEach(shift => sanitizeShift(shift));
    state.actionsHistory.splice(
      state.actionsHistoryPosition,
      state.actionsHistory.length,
      {
        action: 'UPDATE',
        oldShifts,
        newShifts: payload.data,
      },
    );
  },
  historyAddDelete(state, payload) {
    const shiftsState = store.getters['planningsShifts/shiftsForCurrentPeriod'];
    shiftsState.forEach(shift => sanitizeShift(shift));
    const idsToDelete = payload.shift_ids;

    const oldShifts = shiftsState.filter(shift => idsToDelete.includes(shift.id));
    oldShifts.forEach(shift => sanitizeShift(shift));
    state.actionsHistory.splice(
      state.actionsHistoryPosition,
      state.actionsHistory.length,
      { action: 'DELETE', shifts: oldShifts },
    );
  },
  historyAddValidateDay(state, payload) {
    state.actionsHistory.splice(
      state.actionsHistoryPosition,
      state.actionsHistory.length,
      { action: 'VALIDATE_DAY', params: payload },
    );
  },
  historyAddValidateWeek(state, payload) {
    state.actionsHistory.splice(
      state.actionsHistoryPosition,
      state.actionsHistory.length,
      { action: 'VALIDATE_WEEK', params: payload },
    );
  },
  // To avoid history being too big and lagging the planning,
  // we limit it's size by splicing it when it's too large
  incrementHistory(state) {
    if (state.actionsHistory.length > MAX_HISTORY_LENGTH) {
      state.actionsHistory.splice(0, state.actionsHistory.length - MAX_HISTORY_LENGTH);
    } else {
      state.actionsHistoryPosition += 1;
    }
  },
  decrementHistory(state) {
    state.actionsHistoryPosition -= 1;
  },
  clearHistory(state) {
    state.actionsHistory.length = 0;
    state.actionsHistoryPosition = 0;
  },
  undoRedoPending(state) {
    state.undoRedoLoading = true;
  },
  undoRedoComplete(state) {
    state.undoRedoLoading = false;
  },
  setMaskToRows(state, newValue) {
    state.isMaskDisplayed = newValue;
  },
  setAbsenceSidePanelOpeningOrigin(state, value) {
    state.absenceSidePanelOpeningOrigin = value;
  },
  // WeeklyAttendanceSheetSignatures
  setWeeklyAttendanceSheetSignatures(state, payload) {
    const incomingSignatures = payload.data;

    const customSignaturesToKeep = state.weeklyAttendanceSheetSignatures
      .reduce((accumulator, currentSignature) => {
        const isAlreadyInSvc = () => incomingSignatures.some(
          incomingSignature => incomingSignature.employeeId === currentSignature.employeeId,
        );

        if (!currentSignature.isCustom || isAlreadyInSvc()) {
          return accumulator;
        }

        accumulator.push(currentSignature);
        return accumulator;
      }, []);

    state.weeklyAttendanceSheetSignatures = incomingSignatures.concat(customSignaturesToKeep);
  },
  addWeeklyAttendanceSheetSignatures(state, values) {
    state.weeklyAttendanceSheetSignatures = state.weeklyAttendanceSheetSignatures.concat(values);
  },
  weeklyAttendanceSheetSignaturesComplete(state) {
    state.weeklyAttendanceSheetSignaturesLoading = false;
  },
  weeklyAttendanceSheetSignaturesPending(state) {
    state.weeklyAttendanceSheetSignaturesLoading = true;
  },
};

const actions = {
  fetchWeeklyOptions({ commit, getters }, shopId) {
    commit('weeklyOptionsPending');

    const params = { shop_id: shopId, date: getters.currentDate };
    return httpClient
      .get('/v3/api/weekly_options', { params })
      .then(response => {
        commit('setWeeklyOptions', response.data);
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('weeklyOptionsComplete');
      });
  },
  fetchMonthlyOptions({ commit, getters }, { shopId, startDate = null, endDate = null }) {
    commit('monthlyOptionsPending');

    const params = {
      shop_id: shopId,
      date: startDate ?? getters.firstMondayOfMonth,
      endrange_date: endDate ?? getters.lastSundayOfMonth,
    };
    return httpClient
      .get('/v3/api/weekly_options/list', { params })
      .then(response => {
        commit('setMonthlyOptions', response.data);
        return response.data;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('monthlyOptionsComplete');
      });
  },
  refreshWeeklyOptions({ state, dispatch }, shopId) {
    if (state.weeklyOptions.attributes?.employeesWithChange?.length !== 0) return;
    dispatch('fetchWeeklyOptions', shopId);
  },
  refreshMonthlyOptions({ state, dispatch }, shopId) {
    if (state.monthlyOptions.some(weeklyOption => weeklyOption.employeesWithChange.length !== 0)) {
      return;
    }

    dispatch('fetchMonthlyOptions', { shopId });
  },
  fetchShopPlanningConfig({ commit }, shopId) {
    commit('shopPlanningConfigPending');

    const params = { shop_id: shopId };
    return httpClient
      .get('/v3/api/plannings/shop/planning_config', { params })
      .then(response => {
        commit('setShopPlanningConfig', response.data);
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('shopPlanningConfigSuccess');
      });
  },
  fetchUserPlanningConfig({ commit }, shopId) {
    commit('userPlanningConfigPending');

    // There is no need to pass the :userId inside the route.
    // The :userId is already included in the request headers which is also used to set the currentUser
    // Todo Fix context shopId
    return httpClient
      .get(`/v3/api/plannings/user/planning_config?shop_id=${shopId}`)
      .then(response => {
        commit('setUserPlanningConfig', response.data);
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('userPlanningConfigSuccess');
      });
  },
  fetchShopAlerts({ commit }, shopId) {
    commit('shopAlertsPending');

    return httpClient
      .get(`/v3/api/shops/${shopId}/alerts`)
      .then(response => {
        commit('setShopAlerts', response.data);
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('shopAlertsSuccess');
      });
  },
  updateShopPlanningConfig({ commit }, params) {
    commit('shopPlanningConfigPending');

    return httpClient
      .patch('/v3/api/plannings/shop/planning_config', params)
      .then(response => {
        commit('setShopPlanningConfig', response.data);
      })
      .catch(error => {
        commit('setError', error.response);
      })
      .finally(() => {
        commit('shopPlanningConfigSuccess');
      });
  },
  updateUserPlanningConfig({ commit }, params) {
    commit('userPlanningConfigPending');

    return httpClient
      .patch('/v3/api/plannings/user/planning_config', params)
      .then(response => {
        commit('setUserPlanningConfig', response.data);
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('userPlanningConfigSuccess');
      });
  },
  validatePeriod({ commit }, params) {
    // Handle blue lock spinner for days and week
    const dayIndex = skDate(params.startDate).dayIndex();
    if (params.dayLock) {
      commit('validateDayPending', dayIndex);
    } else {
      commit('validatePeriodPending');
    }

    const validateParams = {
      shop_id: params.shopId,
      start_date: params.startDate,
      end_date: params.endDate,
      // This params indicates the weekly_option to return from backend
      current_date: params.currentDate,
      validation_level: params.validationLevel,
      validation_value: params.validationValue,
    };

    return httpClient
      .post('/v3/api/weekly_options/validate_period', validateParams)
      .then(response => {
        // If the validate period isn't covering current week,
        // no need to update, backend returns nothing
        if (response.data.data) {
          commit('setWeeklyOptions', response.data);
        }
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('validatePeriodComplete');
        commit('validateDayComplete', dayIndex);
      });
  },
  deactivateAlert({ commit }, params) {
    const deactivateAlertParams = {
      shop_id: params.shopId,
      employee_id: params.employeeId,
      alert_id: params.alertId,
      date: params.date,
    };
    return httpClient
      .patch('/v3/api/weekly_options/deactivate_alert', deactivateAlertParams)
      .then(response => {
        commit('setWeeklyOptions', response.data);
        commit('planningsShifts/removeAlertForEmployee',
          { employeeId: params.employeeId, alertId: params.alertId },
          { root: true },
        );
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      });
  },
  unlockRequest({ commit }, params) {
    commit('unlockRequestPending');

    const unlockRequestParams = {
      shop_id: params.shopId,
      type: params.type,
      date: params.date,
      explanation: params.explanation,
    };

    return httpClient
      .post('/v3/api/weekly_options/unlock_request', unlockRequestParams)
      .then(response => (
        response
      ))
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('unlockRequestComplete');
      });
  },
  fetchEvents({ commit }, params) {
    commit('eventsFetchPending');

    return httpClient
      .get('/v3/api/plannings/events', { params })
      .then(response => {
        commit('setEvents', response.data);
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('eventsFetchComplete');
      });
  },
  createEvent({ commit }, params) {
    commit('eventRequestPending');

    const eventParams = {
      ...params,
      shop_id: store.state.currentShop.currentShop.id,
    };

    return httpClient
      .post('/v3/api/plannings/events', { ...eventParams })
      .then(response => {
        commit('createEventSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('eventRequestComplete');
      });
  },
  updateEvent({ commit }, params) {
    commit('eventRequestPending');

    const eventParams = {
      ...params,
      shop_id: store.state.currentShop.currentShop.id,
    };

    return httpClient
      .patch(`/v3/api/plannings/events/${params.id}`, { ...eventParams })
      .then(response => {
        commit('updateEventSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('eventRequestComplete');
      });
  },
  destroyEvent({ commit }, id) {
    commit('eventDeletePending');

    return httpClient
      .delete(`/v3/api/plannings/events/${id}?shop_id=${store.state.currentShop.currentShop.id}`)
      .then(() => {
        commit('deleteEventSuccess', id);
        return true;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('eventDeleteComplete');
      });
  },
  fetchHolidays({ commit }, params) {
    commit('holidayFetchPending');

    return httpClient
      .get('/v3/api/plannings/events/holidays', { params })
      .then(response => {
        commit('setHolidays', response.data);
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('holidayFetchComplete');
      });
  },

  undoRedoAction({ commit, dispatch, getters }, params) {
    commit('undoRedoPending');
    const lastAction = params.isRedo ? getters.nextAction : getters.previousAction;
    let actionName = lastAction.action;
    if (params.isRedo) {
      switch (actionName) {
        // when using redo we reverse the action
        case 'CREATE': actionName = 'DELETE'; break;
        case 'DELETE': actionName = 'CREATE'; break;
        default: break;
      }
    }
    let dispatchActions;
    switch (actionName) {
      case 'CREATE':
        dispatchActions = dispatch({
          type: 'planningsShifts/deleteShifts',
          shop_id: store.state.currentShop.currentShop.id,
          shift_ids: lastAction.shifts.map(shift => shift.id),
          starts_at: params.periodStartsAt,
          ends_at: params.periodEndsAt,
          isUndo: true,
        }, { root: true });
        break;
      case 'DELETE':
        // Only display loading screen when creating more than a week of shifts
        if (lastAction.shifts.length > 7) {
          commit('planningsShifts/shiftsRequestPending', false, { root: true });
        }
        dispatchActions = dispatch({
          type: 'planningsShifts/createShift',
          shopId: store.state.currentShop.currentShop.id,
          shifts: lastAction.shifts,
          isUndo: true,
          periodStartsAt: params.periodStartsAt,
          periodEndsAt: params.periodEndsAt,
        }, { root: true });
        break;
      case 'UPDATE': {
        const shifts = params.isRedo ? lastAction.newShifts : lastAction.oldShifts;
        dispatchActions = dispatch({
          type: 'planningsShifts/updateShift',
          shopId: store.state.currentShop.currentShop.id,
          shifts,
          isUndo: true,
          periodStartsAt: params.periodStartsAt,
          periodEndsAt: params.periodEndsAt,
          skipUpsertShift: params.skipUpsertShift,
        }, { root: true });

        break;
      }
      case 'FETCH': {
        commit('planningsShifts/shiftsRequestPending', false, { root: true });

        const shiftsToCreate = params.isRedo ? lastAction.addedShifts : lastAction.deletedShifts;
        const shiftsToDelete = params.isRedo ? lastAction.deletedShifts : lastAction.addedShifts;

        const deleteParams = {
          type: 'planningsShifts/deleteShifts',
          shop_id: store.state.currentShop.currentShop.id,
          shift_ids: shiftsToDelete.map(shift => shift.id),
          starts_at: params.periodStartsAt,
          ends_at: params.periodEndsAt,
          isUndo: true,
        };

        shiftsToCreate.forEach(shift => sanitizeShift(shift));
        shiftsToDelete.forEach(shift => sanitizeShift(shift));

        const createParams = {
          type: 'planningsShifts/createShift',
          shopId: store.state.currentShop.currentShop.id,
          shifts: shiftsToCreate,
          isUndo: true,
          periodStartsAt: params.periodStartsAt,
          periodEndsAt: params.periodEndsAt,
        };

        if (shiftsToDelete.length && shiftsToCreate.length) {
          // delete needs to occure before create to avoid conflicting ids
          dispatchActions = dispatch(deleteParams, { root: true })
            .then(() => dispatch(createParams, { root: true }));
        } else if (shiftsToCreate.length) {
          dispatchActions = dispatch(createParams, { root: true });
        } else if (shiftsToDelete.length) {
          dispatchActions = dispatch(deleteParams, { root: true });
        }

        break;
      }
      case 'VALIDATE_DAY': {
        const validation = params.isRedo ?
          lastAction.params.validation : !lastAction.params.validation;
        dispatchActions = dispatch({
          type: 'validateDay',
          shopId: store.state.currentShop.currentShop.id,
          date: lastAction.params.date,
          validation,
          isUndo: true,
        });

        break;
      }
      case 'VALIDATE_WEEK': {
        const validation = params.isRedo ?
          lastAction.params.validation : !lastAction.params.validation;
        dispatchActions = dispatch({
          type: 'validateWeek',
          shopId: store.state.currentShop.currentShop.id,
          date: lastAction.params.date,
          validation,
          isUndo: true,
        });

        break;
      }
      default:
        throw new Error('Unsupported action');
    }
    return Promise.resolve(dispatchActions).then(response => {
      commit(params.isRedo ? 'incrementHistory' : 'decrementHistory');
      return response;
    }).catch(error => {
      commit('setError', error);
      throw error;
    }).finally(() => {
      commit('undoRedoComplete');
      commit('planningsShifts/shiftsRequestComplete', false, { root: true });
    });
  },

  fetchWeeklyAttendanceSheetSignatures({ commit, getters }, shopId) {
    commit('weeklyAttendanceSheetSignaturesPending');

    const params = {
      shop_id: shopId,
      starts_at: getters.monday,
      ends_at: getters.sunday,
    };

    return httpClient
      .get('/v3/api/request_esignatures', { params })
      .then(response => {
        commit('setWeeklyAttendanceSheetSignatures', response);
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('weeklyAttendanceSheetSignaturesComplete');
      });
  },
};

const gettersList = {
  isDailyView: (_state, _getters, rootState) => rootState.route.name === 'plannings_days',
  isWeeklyView: (_state, _getters, rootState) => rootState.route.name.includes('plannings_weeks'),
  isMonthlyView: (_state, _getters, rootState) => rootState.route.name === 'plannings_months',
  isEmployeesView: (_state, _getters, rootState) => rootState.route.name === 'plannings_weeks_employees',
  isPostesView: (_state, _getters, rootState) => rootState.route.name === 'plannings_weeks_postes',
  isActiveItem: state => item => state.totalRowSelectedItem === item,
  isAnyFilterActiveOnPlanning: state => state.filters && (
    state.filters.postes?.length > 0 || state.filters.teams?.length > 0
  ),
  isPlanningLoading(state) {
    const isShiftsRelatedDataLoading = store.state.planningsShifts.shiftsLoading ||
      store.state.planningsUsers.availabilitiesLoading ||
      store.state.shopTeams.fetchTeamScheduleLoading;
    const areBatchesLoading =
      store.state.planningsLoading.planningDataStatus === PLANNING_DATA_STATUS.LOADING_BATCHES;

    // If we are progressively loading weekly data we shouldn't consider
    // other planning data being loaded because it's being loaded in the background
    const isSyncPlanningDataLoading =
      isShiftsRelatedDataLoading && !areBatchesLoading;

    // If we are still loading global data, show the spinner
    const isGlobalDataLoading = state.shopPlanningConfigLoading ||
      store.state.planningsUsers.usersLoading || store.state.shopTeams.teamsLoading ||
      state.eventsLoading || state.holidayLoading ||
      state.shopAlertsLoading;

    return isSyncPlanningDataLoading || isGlobalDataLoading;
  },
  isAnyDayLocked(state, getters) {
    if (state.weeklyOptionsLoading) return false;

    if (getters.isDailyView) {
      const dateIndex = skDate.utc(getters.currentDate).dayIndex();

      return !!state.weeklyOptions.attributes.permanentLockedDays[dateIndex] ||
        !!state.weeklyOptions.attributes.validatedDays[dateIndex] ||
        !!state.weeklyOptions.attributes.intermediateLockedDays[dateIndex];
    }

    return state.weeklyOptions.attributes.permanentLockedDays.includes(true) ||
      state.weeklyOptions.attributes.validatedDays.includes(true) ||
      state.weeklyOptions.attributes.intermediateLockedDays.includes(true);
  },
  isDayLockedForShift: state => shift => {
    if (state.weeklyOptionsLoading) return false;

    const { shopOpeningTime, shopClosingTime, startsAt } = shift.attributes;
    const shiftStartsAt = skDate.utc(startsAt);
    let dateIndex = shiftStartsAt.dayIndex();

    const shopOpening = openingAndClosingTimeAt(
      shopOpeningTime,
      shopClosingTime,
      startsAt).openingTime;

    // If shift starts before shop opening time, set dateIndex to previous day
    // -> to handle shop with night hours
    if (shiftStartsAt.isBefore(shopOpening)) {
      dateIndex -= 1;
      if (dateIndex === -1) {
        dateIndex = 6; // Sunday
      }
    }

    return !!state.weeklyOptions.attributes.permanentLockedDays[dateIndex] ||
      !!state.weeklyOptions.attributes.validatedDays[dateIndex] ||
      !!state.weeklyOptions.attributes.intermediateLockedDays[dateIndex];
  },
  currentDate: (_state, _getters, rootState) => {
    const storagePlanningDate = localStorage.planningDate;
    const statePlanningDate = rootState.route.query.date;

    const shouldUpdateLocalStorage = !storagePlanningDate ||
      (statePlanningDate && statePlanningDate !== storagePlanningDate &&
        skDate(statePlanningDate).isValid());

    if (shouldUpdateLocalStorage) {
      const newDate = skDate(statePlanningDate).isValid() ? skDate(statePlanningDate) : skDate();
      localStorage.planningDate = newDate.format('YYYY-MM-DD');
    }
    return localStorage.planningDate;
  },
  monday: (_state, getters) => skDate(getters.currentDate).startOf('isoWeek').format('YYYY-MM-DD'),
  sunday: (_state, getters) => skDate(getters.currentDate).endOf('isoWeek').format('YYYY-MM-DD'),
  nextMonth: (_state, getters) => skDate(getters.currentDate).add(1, 'month').format('YYYY-MM-DD'),
  firstMonthDay: (_state, getters) => skDate(getters.currentDate).startOf('month').format('YYYY-MM-DD'),
  lastMonthDay: (_state, getters) => skDate(getters.currentDate).endOf('month').format('YYYY-MM-DD'),
  firstMondayOfMonth: (_state, getters) => skDate(getters.firstMonthDay).startOf('isoWeek').format('YYYY-MM-DD'),
  lastSundayOfMonth: (_state, getters) => skDate(getters.lastMonthDay).endOf('isoWeek').format('YYYY-MM-DD'),
  firstMondayOfCurrentPeriod: (_state, getters) => {
    if (getters.isDailyView) return getters.monday;
    if (getters.isWeeklyView) return getters.monday;
    return getters.firstMondayOfMonth;
  },
  weeksToFetch: (_state, getters) => {
    if (getters.isDailyView || getters.isWeeklyView) return 1;
    return skDate(getters.lastSundayOfMonth).diff(skDate(getters.firstMondayOfMonth), 'weeks') + 1;
  },
  // For daily and weekly view : we use start and end of week as limit
  // For monthly view : we use first day and last sunday of month as limit
  periodDates: (_state, getters) => period => {
    const startsAt = period === 'week' ? getters.monday : getters.firstMondayOfMonth;
    const endsAt = period === 'week' ? getters.sunday : getters.lastSundayOfMonth;
    return { startsAt, endsAt };
  },
  monthlyVisibleWeeks: (_state, getters) => {
    const weeksData = {};
    getters.monthlyVisibleDays.forEach(day => {
      const weekStart = skDate(day.date).startOf('isoWeek').format('YYYY-MM-DD');
      if (!weeksData[weekStart]) {
        weeksData[weekStart] = {
          from: weekStart,
          to: skDate(day.date).endOf('isoWeek').format('YYYY-MM-DD'),
          days: [],
        };
      }
      weeksData[weekStart].days.push(day);
    });
    return weeksData;
  },
  visibleDays(state, getters) {
    // Weekly visible days
    // We need weeklyOptions to be loaded to compute visible days
    // If it's not loaded yet, we return an empty array
    if (!state.weeklyOptions.attributes) return [];
    const currentShop = store.state.currentShop.currentShop;
    const openingTime = currentShop.attributes.openingTime;
    const [hour, minute] = openingTime.split(':');
    const initialDate = skDate(getters.monday);
    return state.shopPlanningConfig.attributes ?
      formatVisibleDays(
        [state.weeklyOptions.attributes],
        {
          visibleDays: state.shopPlanningConfig.attributes.visibleDays,
          days: 7,
        },
        { hour, minute },
        { events: state.events, holidays: state.holidays, initialDate }) :
      [];
  },
  monthlyVisibleDays(state, getters) {
    const currentShop = store.state.currentShop.currentShop;
    const openingTime = currentShop.attributes.openingTime;
    const [hour, minute] = openingTime.split(':');
    const initialDate = skDate(getters.firstMondayOfMonth);
    const endDate = skDate(getters.lastSundayOfMonth).endOf('isoWeek');
    const daysUntilMonthEnds = endDate.diff(initialDate, 'days');
    const days = daysUntilMonthEnds > 35 ? 42 : 35;
    return state.shopPlanningConfig.attributes ?
      formatVisibleDays(
        state.monthlyOptions,
        {
          visibleDays: Array(7).fill(true), // All weekdays are visible in monthly view
          days,
        },
        { hour, minute },
        { events: state.events, holidays: state.holidays, initialDate }) :
      [];
  },
  isDayVisible: state => date => {
    // -1 because isoWeekday() returns 1 for monday and 7 for sunday
    // however visibleDaysArray has index 0 for monday and 6 for sunday
    const dayIndex = skDate(date).isoWeekday() - 1;

    return state.shopPlanningConfig.attributes.visibleDays[dayIndex];
  },
  nextVisibleDay: (_, getters) => date => {
    const visibleDates = getters.visibleDays.map(visibleDay => visibleDay.date);
    const lastVisibleDay = skDate.utc(visibleDates[visibleDates.length - 1]);
    const nextVisibleDay = skDate.utc(date);

    // If the next day is not available in current week we wrap around to the first available day of the next week
    while (nextVisibleDay.isSameOrBefore(lastVisibleDay)) {
      for (let i = 0; i < visibleDates.length; i += 1) {
        const formattedNextVisibleDay = nextVisibleDay.format('YYYY-MM-DD');
        if (formattedNextVisibleDay === visibleDates[i]) {
          return formattedNextVisibleDay;
        }
      }

      nextVisibleDay.add(1, 'd');
    }

    return skDate.utc(visibleDates[0]).add(1, 'w').format('YYYY-MM-DD');
  },
  previousVisibleDay: (_, getters) => date => {
    const visibleDates = getters.visibleDays.map(visibleDay => visibleDay.date);
    const firstVisibleDay = skDate.utc(visibleDates[0]);
    const previousVisibleDay = skDate.utc(date);

    // If the previous day is not available in current week we wrap around to the last available day of the previous week
    while (previousVisibleDay.isSameOrAfter(firstVisibleDay)) {
      for (let i = visibleDates.length - 1; i >= 0; i -= 1) {
        const formattedPreviousVisibleDay = previousVisibleDay.format('YYYY-MM-DD');
        if (formattedPreviousVisibleDay === visibleDates[i]) {
          return formattedPreviousVisibleDay;
        }
      }

      previousVisibleDay.subtract(1, 'd');
    }

    return skDate.utc(visibleDates[visibleDates.length - 1]).subtract(1, 'w').format('YYYY-MM-DD');
  },
  visibleDaysConfig: state => state.shopPlanningConfig.attributes.visibleDays,
  isHistoryEmpty: state => state.actionsHistory.length === 0,
  previousAction: state => state.actionsHistory[state.actionsHistoryPosition - 1],
  nextAction: state => state.actionsHistory[state.actionsHistoryPosition],
  isAtBeginningOfHistory: state => state.actionsHistoryPosition === 0,
  isAtEndOfHistory: state => state.actionsHistoryPosition === state.actionsHistory.length,
  getActiveAlertsList: state => state.shopAlerts
    .filter(alert => alert.attributes.active)
    .map(alert => alert.attributes.name),
  currentEvent: (state, getters) => (
    state.events.find(event => event.attributes.date === getters.currentDate)
  ),
  isOnlyOneDayUnlocked: state => (
    // Used to trigger the shift alert modal
    // when we lock day by day to not be able to
    // lock the whole week when there are blocking alerts
    state.weeklyOptions.attributes.validatedDays.filter(day => !day).length === 1
  ),
  areUnassignedShiftsAllowed(state) {
    return (state.shopPlanningConfig.attributes.allowUnassignedShifts &&
      store.getters['currentOrganisation/checkPackOfferFlag']('unassigned_shifts_enabled'));
  },
  getWeeklyOptionsForAWeek: state => date => state.monthlyOptions.find(weekOption => {
    const monday = skDate.utc(weekOption.date).format('YYYY-MM-DD');

    return monday === skDate.utc(date).format('YYYY-MM-DD');
  }),
  // Returns an array of all the days locked in green
  // that are visible on the planning, without counting the visible days on the period
  // The function isWeekIntermediaryLocked count for the full week and not date to date
  visibleLockedDays: state => lockedDays => {
    const visibleLockedDays = cloneDeep(lockedDays);

    // Loop through visibleDays array to get the index
    // And remove all the days that should not be visible from locked days.
    state.shopPlanningConfig.attributes?.visibleDays.forEach((visibleDay, index) => {
      if (!visibleDay) visibleLockedDays.splice(index, 1);
    });

    return visibleLockedDays;
  },
  // Returns false if at least one day is not locked
  // It counts for the full week and not date to date
  isWeekIntermediaryLocked: (_state, selfGetters) => weeklyOption => (
    selfGetters.visibleLockedDays(weeklyOption.intermediateLockedDays).every(Boolean)
  ),
  // Removes the element(s) that are not part of the period.
  // For exemple: if firstDay is a tuesday we want to remove monday from the list.
  //
  // Parameter:
  // firstDay [String]: startDate of the period selected on the report
  // lastDay  [String]: endDate of the period selected on the report
  // days     [Array]: list of true or false values that represent the days of the week.
  // weeklyOptionIndex [Integer]: index of the weeklyOption in the period.
  daysOfWeekInPeriod: (_state, _selfGetters, rootState) => ({
    firstDay, lastDay, days, weeklyOptionIndex,
  }) => {
    const firstDayOfPeriod = skDate(firstDay).day();
    const lastDayOfPeriod = skDate(lastDay).day();
    const localDays = cloneDeep(days);

    // The first day of the week is not necessarily a monday in the period,
    // Here we splice the number of days between startDate and monday if startDate is after monday.
    if (firstDayOfPeriod > 1 && weeklyOptionIndex === 0) {
      return localDays.splice(firstDayOfPeriod - 1);
    }

    // We want to count all the days in the period,
    // So if the week is not active on the report, we still want to count the days
    // until the end of the period.
    if (!rootState.report.saasReportInfos?.weeks_datas?.[weeklyOptionIndex]?.week_active &&
        lastDayOfPeriod > 0) {
      return localDays.splice(0, lastDayOfPeriod);
    }

    return localDays;
  },
  // Removes the elements that are not visible on the planning.
  //
  // Parameter:
  // firstDay [String]: startDate of the period selected on the report
  // lastDay  [String]: endDate of the period selected on the report
  // days     [Array]: list of true or false values that represent the days of the week.
  // weeklyOptionIndex [Integer]: index of the weeklyOption in the period.
  daysOfWeekInPeriodWithoutVisibleDays: (state, selfGetters) => ({
    firstDay, lastDay, days, weeklyOptionIndex,
  }) => {
    // Get only the visible days of the week that are in the period
    // Ex: if the period starts on tuesday, we want to remove monday from the list
    const visibleDays = selfGetters.daysOfWeekInPeriod({
      firstDay,
      lastDay,
      days: state.shopPlanningConfig.attributes?.visibleDays || [],
      weeklyOptionIndex,
    });

    // Remove each element from days that are not visible on the planning
    visibleDays.forEach((visibleDay, visibleDayIndex) => {
      if (!visibleDay) days.splice(visibleDayIndex, 1);
    });

    return days;
  },
  isShiftInFilters: (_state, _getters, rootState) => shift => {
    const filteredPosteIds = rootState.route.query.poste_ids || [];

    if (filteredPosteIds.length === 0) return true;

    // Filter by poste when it's a pending leave request
    const id = shift.attributes.isPendingLeaveRequest ?
      shift.attributes.posteId.toString() :
      shift.relationships.poste.id;

    return filteredPosteIds.includes(id);
  },
  isGreenLockActivated: _ => (
    store.getters['employees/canAccessPayrollReport']
  ),
  isEmployeePlanningSpace: (_state, _getters, rootState) => rootState.route.name?.startsWith('plannings_employee'),
  isDayViewScrollable: state => (
    !store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_REACTIVE_DAY_VIEW_CANARY') ||
      state.dayViewPlanningSizeVariables.pixelPerMinute === 1
  ),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersList,
};
