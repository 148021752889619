import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import first from 'lodash/first';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import skDate from '@skello-utils/dates';
import store from '@app-js/shared/store/index';
import {
  associationMatcherItem,
  associationMatcherCollection,
} from '@skello-utils/association_matchers';
import { capitalize } from '@skello-utils/formatting/strings';
import {
  httpClient,
  svcEmployeesClient,
} from '@skello-utils/clients';
import { MANDATORY_ATTRIBUTE_TO_TAB } from '@app-js/shared/constants/payroll_employee_attributes';
import { MANDATORY_ATTRIBUTES_FOR_DPAE } from '@app-js/shared/constants/dpae_attributes';

const initialState = {
  employee: {
    id: '',
    type: 'user',
    attributes: {},
    relationships: {},
  },
  originalEmployeeData: {
    id: '',
    type: 'user',
    attributes: {},
    relationships: {},
  },
  originalEmployeeLicenseData: {},
  currentUserVisibleNodes: [],
  error: false,
  inputError: false,
  inputErrorAttributes: {},
  loading: false,
  updatingEmployee: false,
  unarchiveRequestPending: false,
  archiveRequestPending: false,
  inviteRequestPending: false,
  employeeWithTeamScheduleSelected: {},
  areEmployeeAnnualizationConfigsLoading: false,
  employeeAnnualizationConfig: null,
  originalEmployeeAnnualizationConfig: null,
  errorsAnnualization: {},
  areEmployeeAnnualizationConfigsUpdateLoading: false,
};

const matchAssociatedItem = (employee, included, key, type) => {
  const relationship = {};
  relationship[key] = associationMatcherItem(employee, included, { key, type });
  Object.assign(employee.relationships, relationship);
  return employee;
};

const matchAssociatedCollection = (employee, included, key, type) => {
  const relationship = {};
  relationship[key] = associationMatcherCollection(employee, included, { key, type });
  Object.assign(employee.relationships, relationship);
  return employee;
};

const markForDestructionOrRemoveFromCollection = (collection, item) => {
  if (item.id) {
    Vue.set(item, '_destroy', true);
  } else {
    const index = collection.indexOf(item);
    collection.splice(index, 1);
  }
};

// This function is used to get the intersection between a collection of items and a selection of ids
// using a discriminating attribute id in the collection
const intersectionOfExistingAndSelected = (collection, selectionIds, discriminatingAttributeId) => {
  const castedSelectionIds = selectionIds.map(item => Number(item));
  return collection.filter(item => {
    const castedAttributeId = Number(item.attributes[discriminatingAttributeId]);
    return castedSelectionIds.includes(castedAttributeId);
  });
};

// This function is used to get the outer section between a collection of items and a selection of ids
// using a discriminating attribute id in the collection
const outerSectionOfExistingAndSelected = (collection, selectionIds, discriminatingAttributeId) => {
  const castedSelectionIds = selectionIds.map(item => Number(item));
  return collection.filter(item => {
    const castedAttributeId = Number(item.attributes[discriminatingAttributeId]);
    return !castedSelectionIds.includes(castedAttributeId);
  });
};

const mutations = {
  setEmployeeAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.employee.attributes[attribute] = payload[attribute];
    });
  },

  setContractAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.employee.relationships.contract.attributes[attribute] = payload[attribute];
    });
  },

  squashOriginalEmployee(state) {
    state.originalEmployeeData = cloneDeep(state.employee);
  },

  squashChangesToEmployee(state) {
    state.employee = cloneDeep(state.originalEmployeeData);
  },

  updatePrimaryShop(state, { newNode, standardEmployeeLicense }) {
    const activeMembershipShopId =
      state.employee.relationships.memberships.map(mB => String(mB.attributes.shopId));

    const isSystemAdmin = (state.employee.relationships.userLicenses.length === 1 &&
      state.employee.relationships.userLicenses[0].attributes.licensePosition === 0);

    const activeUserLicenseClusterNodeId =
      state.employee.relationships.userLicenses.map(uL => String(uL.attributes.clusterNodeId));

    if (!activeMembershipShopId.includes(String(newNode.attributes.shopId))) {
      state.employee.relationships.memberships.push({
        id: null,
        type: 'membership',
        attributes: {
          inPlanning: false,
          shopId: newNode.attributes.shopId,
          shopName: newNode.text,
          clusterNodeId: newNode.attributes.clusterNodeId,
        },
      });
    }

    if (!activeUserLicenseClusterNodeId.includes(newNode.id) && !isSystemAdmin) {
      state.employee.relationships.userLicenses.push({
        id: null,
        type: 'userLicense',
        attributes: {
          licenseId: standardEmployeeLicense.id,
          licenseName: standardEmployeeLicense.attributes.name,
          licensePosition: standardEmployeeLicense.attributes.position,
          name: newNode.text,
          shopId: String(newNode.attributes.shopId),
          userId: state.employee.id,
          clusterNodeId: newNode.id,
          parentId: String(newNode.attributes.parentId),
          depth: newNode.attributes.depth,
          ancestry: newNode.attributes.ancestry,
          ancestorIds: newNode.attributes.ancestorIds,
          descendantIds: newNode.attributes.descendantIds,
        },
      });
    }
  },

  updatePrimaryNode(state, { newNode, standardEmployeeLicense, primaryShopNode }) {
    const activeUserLicenseChildren =
      state.employee.relationships.userLicenses.map(uL => uL.attributes.descendantIds).flat();

    const oldNode = state.employee.relationships.userLicenses.find(activeNode => (
      String(activeNode.attributes.shopId) === String(primaryShopNode.attributes.shopId) ||
      activeNode.attributes.descendantIds.includes(Number(primaryShopNode.id))
    ));

    const oldShopId = Number(oldNode.attributes.shopId);
    const oldMembership = state.employee.relationships.memberships.find(
      membership => Number(membership.attributes.shopId) === oldShopId,
    );

    const activeMembershipShopId = state.employee.relationships.memberships
      .map(mB => String(mB.attributes.shopId));
    const activeUserLicenseClusterNodeId = state.employee.relationships.userLicenses
      .filter(uL => !uL._destroy)
      .map(uL => String(uL.attributes.clusterNodeId));
    const activeUserLicenseShopId = state.employee.relationships.userLicenses
      .filter(uL => !uL._destroy)
      .map(uL => String(uL.attributes.shopId));

    const isSystemAdmin = (state.employee.relationships.userLicenses.length === 1 &&
      state.employee.relationships.userLicenses[0].attributes.licensePosition === 0);

    if (!activeMembershipShopId.includes(String(newNode.attributes.shopId))) {
      state.employee.relationships.memberships.push({
        id: null,
        type: 'membership',
        attributes: {
          inPlanning: !isSystemAdmin,
          shopId: newNode.attributes.shopId,
          shopName: newNode.text,
          clusterNodeId: newNode.id,
        },
      });
    }

    if (activeUserLicenseChildren.includes(Number(newNode.id))) {
      markForDestructionOrRemoveFromCollection(
        state.employee.relationships.userLicenses,
        newNode,
      );
    } else if (!activeUserLicenseClusterNodeId.includes(newNode.id) && !isSystemAdmin) {
      if (activeUserLicenseShopId.includes(String(primaryShopNode.attributes.shopId))) {
        markForDestructionOrRemoveFromCollection(
          state.employee.relationships.userLicenses,
          oldNode,
        );
        markForDestructionOrRemoveFromCollection(
          state.employee.relationships.memberships,
          oldMembership,
        );
      }

      const existingUl = state.employee.relationships.userLicenses.find(
        uL => uL.attributes.clusterNodeId === newNode.id,
      );

      if (existingUl) {
        Vue.set(existingUl, '_destroy', false);
        return;
      }

      state.employee.relationships.userLicenses.push({
        id: null,
        type: 'userLicense',
        attributes: {
          licenseId: Number(standardEmployeeLicense.id),
          licenseName: standardEmployeeLicense.attributes.name,
          licensePosition: standardEmployeeLicense.attributes.position,
          name: newNode.text,
          shopId: newNode.attributes.shopId,
          userId: state.employee.id,
          clusterNodeId: newNode.id,
          ancestorIds: newNode.attributes.ancestorIds,
          descendantIds: newNode.attributes.descendantIds,
          depth: newNode.attributes.depth,
          parentId: newNode.attributes.parentId,
          editable: newNode.attributes.editable,
          ancestry: newNode.attributes.ancestry,
        },
      });
    }
  },

  promoteToSystemAdmin(state, { newLicense, nodes }) {
    const rootNodeId = store.state.currentOrganisation.currentOrganisation.attributes.rootNodeId;
    state.employee.relationships.userLicenses = [
      {
        id: null,
        type: 'userLicense',
        attributes: {
          licenseId: newLicense.id,
          licenseName: newLicense.text,
          licensePosition: 0,
          name: store.state.currentOrganisation.currentOrganisation.attributes.name,
          shopId: null,
          userId: state.employee.id,
          clusterNodeId: rootNodeId,
          depth: 0,
        },
      },
    ];

    const existingMemberships = intersectionOfExistingAndSelected(
      state.employee.relationships.memberships,
      nodes.map(node => node.id),
      'clusterNodeId',
    );

    nodes.forEach(node => {
      const preexistingMembership = existingMemberships.find(
        mem => String(mem.attributes.shopId) === String(node.attributes.shopId),
      );
      if (!preexistingMembership) {
        state.employee.relationships.memberships.push({
          id: null,
          type: 'membership',
          attributes: {
            inPlanning: false,
            shopId: Number(node.attributes.shopId),
            shopName: node.attributes.name,
            clusterNodeId: Number(node.id),
          },
        });
      }
    });
  },

  demoteFromSystemAdmin(state, { newLicense, nodes }) {
    const userLicenseIdsByNode = {};
    state.originalEmployeeLicenseData.forEach(ul => {
      userLicenseIdsByNode[ul.attributes.clusterNodeId] = ul.id;
    });
    state.employee.relationships.userLicenses = [];
    const existingMemberships = intersectionOfExistingAndSelected(
      state.employee.relationships.memberships,
      nodes.map(node => node.id),
      'clusterNodeId',
    );
    nodes.forEach(node => {
      const preexistingMembership = existingMemberships.find(
        mem => String(mem.attributes.shopId) === String(node.attributes.shopId),
      );

      const id = userLicenseIdsByNode[Number(node.id)] ?
        userLicenseIdsByNode[Number(node.id)] : null;
      state.employee.relationships.userLicenses.push({
        id,
        type: 'userLicense',
        attributes: {
          licenseId: newLicense.id,
          licenseName: newLicense.text,
          name: node.attributes.name,
          shopId: node.attributes.shopId,
          userId: state.employee.id,
          clusterNodeId: node.attributes.id,
          depth: node.attributes.depth,
        },
      });
      if (!preexistingMembership) {
        state.employee.relationships.memberships.push({
          id: null,
          type: 'membership',
          attributes: {
            inPlanning: false,
            shopId: node.attributes.shopId,
            shopName: node.attributes.name,
            clusterNodeId: node.id,
          },
        });
      }
    });
  },

  manageNodes(state, { selectedNodes }) {
    const selectedNodeIds = selectedNodes.map(node => node.id);
    const isSystemAdmin = state.employee.relationships.userLicenses.length === 1 &&
      state.employee.relationships.userLicenses[0].attributes.licensePosition === 0;

    if (isSystemAdmin) {
      // system admins only have one UL, on the org's root node
      // however, they can have zero+ memberships on shops
      const membershipsToDestroy = outerSectionOfExistingAndSelected(
        state.employee.relationships.memberships,
        selectedNodeIds,
        'clusterNodeId',
      );

      membershipsToDestroy.forEach(mem => (
        markForDestructionOrRemoveFromCollection(state.employee.relationships.memberships, mem)),
      );

      const existingMemberships = intersectionOfExistingAndSelected(
        state.employee.relationships.memberships,
        selectedNodeIds,
        'clusterNodeId',
      );

      selectedNodes.forEach(node => {
        const preexistingNode = existingMemberships.find(
          mem => String(mem.attributes.shopId) === String(node.attributes.shopId),
        );

        if (!preexistingNode) {
          state.employee.relationships.memberships.push({
            id: null,
            type: 'membership',
            attributes: {
              inPlanning: false,
              shopId: node.attributes.shopId,
              shopName: node.attributes.name,
              clusterNodeId: node.id,
            },
          });
        } else if (preexistingNode._destroy) {
          Vue.set(preexistingNode, '_destroy', false);
        }
      });
    } else {
      const userLicensesToDestroy = outerSectionOfExistingAndSelected(
        state.employee.relationships.userLicenses,
        selectedNodeIds,
        'clusterNodeId',
      );

      userLicensesToDestroy.forEach(ul => {
        markForDestructionOrRemoveFromCollection(state.employee.relationships.userLicenses, ul);

        state.employee.relationships.memberships.filter(membership => (
          String(membership.attributes.shopId) === String(ul.attributes.shopId)
        )).forEach(associatedMembership => {
          markForDestructionOrRemoveFromCollection(
            state.employee.relationships.memberships,
            associatedMembership,
          );
        });

        const existingTeamMemberships = state.employee.relationships.teamMemberships.filter(tM => (
          tM.attributes.shopId === ul.attributes.shopId
        ));

        existingTeamMemberships.forEach(existingTeamMembership => {
          Vue.set(existingTeamMembership, '_destroy', true);
        });
      });

      const existingUserLicenses = intersectionOfExistingAndSelected(
        state.employee.relationships.userLicenses,
        selectedNodeIds,
        'clusterNodeId',
      );

      selectedNodes.forEach(node => {
        const preexistingNode = existingUserLicenses.find(
          ul => String(ul.attributes.clusterNodeId) === String(node.id) ||
              ul.attributes.ancestorIds?.includes(Number(node.id)),
        );

        if (!preexistingNode) {
          state.employee.relationships.userLicenses.push({
            id: null,
            type: 'userLicense',
            attributes: {
              licenseId: state.employee.relationships.userLicenses[0].attributes.licenseId,
              licenseName: state.employee.relationships.userLicenses[0].attributes.licenseName,
              licensePosition:
                state.employee.relationships.userLicenses[0].attributes.licensePosition,
              name: node.attributes.name,
              shopId: node.attributes.shopId ? String(node.attributes.shopId) : null,
              userId: state.employee.id,
              clusterNodeId: String(node.id),
              depth: node.attributes.depth,
              ancestry: node.attributes.ancestry,
              shopsCount: node.attributes.shopsCount,
              ancestorIds: node.attributes.ancestorIds,
              descendantIds: node.attributes.descendantIds,
              editable: node.attributes.editable,
            },
          });

          const existingMembership = state.employee.relationships.memberships.find(mem => (
            String(node.attributes.shopId) === String(mem.attributes.shopId)
          ));
          if (node.attributes.shopId && !existingMembership) {
            state.employee.relationships.memberships.push({
              id: null,
              type: 'membership',
              attributes: {
                inPlanning: false,
                shopId: node.attributes.shopId,
                shopName: node.attributes.name,
                clusterNodeId: node.id,
              },
            });
          }
        } else if (preexistingNode._destroy) {
          Vue.set(preexistingNode, '_destroy', false);
          const associatedMembership = state.employee.relationships.memberships.find(
            mem => mem.attributes.clusterNodeId === node.id,
          );

          if (associatedMembership) Vue.set(associatedMembership, '_destroy', false);
        }
      });
    }
  },

  updateExistingUserLicense(state, { clusterNodeId, newLicense }) {
    const existingUserLicense = state.employee.relationships.userLicenses.find(ul => (
      clusterNodeId === ul.attributes.clusterNodeId
    ));

    if (!existingUserLicense) return;

    existingUserLicense.attributes.licenseId = newLicense.id;
    existingUserLicense.attributes.licenseName = newLicense.text;
    existingUserLicense.attributes.licensePosition = newLicense.attributes.position;
  },

  updateMemberships(state, memberships) {
    state.employee.relationships.memberships.filter(membership => membership._destroy)
      .forEach(membership => {
        const index = state.employee.relationships.memberships.indexOf(membership);
        state.employee.relationships.memberships.splice(index, 1);
      });

    // When creating a membership we add a fake membership in store, this will ensure we have the id
    // to allow deleting right after the creation
    state.employee.relationships.memberships.filter(membership => !membership.id)
      .forEach(membership => {
        const index = state.employee.relationships.memberships.indexOf(membership);
        const newMembership = memberships.find(payloadMembership => (
          payloadMembership &&
          payloadMembership.attributes.shopId === parseInt(membership.attributes.shopId, 10) &&
          payloadMembership.attributes.clusterNodeId ===
            parseInt(membership.attributes.clusterNodeId, 10)
        ));

        state.employee.relationships.memberships.splice(index, 1, newMembership);
      });
  },

  updateMembership(state, { inPlanning, node }) {
    const existingMembership = state.employee.relationships.memberships.find(mem => (
      String(node.attributes.shopId) === String(mem.attributes.shopId)
    ));

    if (existingMembership) {
      existingMembership.attributes.inPlanning = inPlanning;
    } else {
      state.employee.relationships.memberships.push({
        id: null,
        type: 'membership',
        attributes: {
          inPlanning,
          shopId: node.attributes.shopId,
          shopName: node.attributes.name,
          clusterNodeId: node.attributes.clusterNodeId,
        },
      });
    }
  },

  manageTeamMemberships(state, { selectedTeams, shopId }) {
    const selectedTeamsIds = selectedTeams.map(team => team.id);

    const existingTeamMemberships = state.employee.relationships.teamMemberships.filter(tM => (
      tM.attributes.shopId === shopId
    ));

    const teamMembershipsToDestroy = outerSectionOfExistingAndSelected(
      existingTeamMemberships,
      selectedTeamsIds,
      'teamId',
    );

    teamMembershipsToDestroy.forEach(teamMembership => {
      markForDestructionOrRemoveFromCollection(
        state.employee.relationships.teamMemberships,
        teamMembership,
      );
    });

    selectedTeams.forEach(team => {
      const existingTeamMembership = existingTeamMemberships.find(teamMembership => (
        teamMembership.attributes.teamId === Number(team.id)
      ));

      if (existingTeamMembership) {
        Vue.set(existingTeamMembership, '_destroy', false);
      } else {
        state.employee.relationships.teamMemberships.push({
          id: null,
          attributes: {
            name: team.text,
            shopId,
            teamId: team.id,
            userId: state.employee.id,
          },
        });
      }
    });
  },

  manageInPlanningShopNodeMemberships(state, { selectedNodes, clusterNode }) {
    const selectedNodesIds = selectedNodes.map(node => node.id);

    const clusterNodeAndDescendantIds = [
      Number(clusterNode.id), ...clusterNode.attributes.descendantIds,
    ];

    const existingMemberships = state.employee.relationships.memberships.filter(
      membership => (
        clusterNodeAndDescendantIds.includes(membership.attributes.clusterNodeId)
      ),
    );

    const inPlanningMembershipsToDestroy = outerSectionOfExistingAndSelected(
      existingMemberships,
      selectedNodesIds,
      'clusterNodeId',
    );

    inPlanningMembershipsToDestroy.forEach(membership => {
      if (state.employee.attributes.shopId === membership.attributes.shopId) {
        membership.attributes.inPlanning = false;
      } else {
        markForDestructionOrRemoveFromCollection(
          state.employee.relationships.memberships,
          membership,
        );
      }
    });

    selectedNodes.forEach(node => {
      const existingMembership = existingMemberships.find(membership => (
        membership.attributes.clusterNodeId === Number(node.id)
      ));
      if (existingMembership) {
        if (existingMembership.attributes.inPlanning === false) {
          existingMembership.attributes.inPlanning = true;
        } else {
          Vue.set(existingMembership, '_destroy', false);
        }
      } else {
        state.employee.relationships.memberships.push({
          id: null,
          type: 'membership',
          attributes: {
            clusterNodeId: Number(node.attributes.clusterNodeId),
            inPlanning: true,
            shopId: node.attributes.shopId,
            shopName: node.attributes.shopName,
          },
        });
      }
    });
  },

  manageCompetencies(state, { selectedPostes, shopId }) {
    const selectedPosteIds = selectedPostes.map(poste => poste.id);

    const existingCompetencies =
      state.employee.relationships.automaticPlanningCompetencies.filter(competency => (
        competency.attributes.shopId === Number(shopId)
      ));

    const competenciesToDestroy = outerSectionOfExistingAndSelected(
      existingCompetencies,
      selectedPosteIds,
      'posteId',
    );

    competenciesToDestroy.forEach(competency => {
      const index = state.employee.relationships.automaticPlanningCompetencies.indexOf(competency);
      state.employee.relationships.automaticPlanningCompetencies.splice(index, 1);
    });

    selectedPostes.forEach(poste => {
      const existingCompetency = existingCompetencies.find(competency => (
        competency.attributes.posteId === Number(poste.id)
      ));

      if (existingCompetency) {
        Vue.set(existingCompetency, '_destroy', false);
        Vue.set(existingCompetency, 'primary', poste.primary);
      } else {
        state.employee.relationships.automaticPlanningCompetencies.push({
          id: null,
          attributes: {
            shopId,
            posteId: poste.id,
            primary: poste.primary,
            userId: state.employee.id,
          },
        });
      }
    });
  },

  performingUpdate(state) {
    state.updatingEmployee = true;
  },

  performingUpdateComplete(state) {
    if (state.employee.id === store.state.currentUser.currentUser.id) {
      store.dispatch('currentUser/fetchCurrentUser');
    }

    state.updatingEmployee = false;
  },

  performingUnarchiveRequest(state) {
    state.unarchiveRequestPending = true;
  },

  unarchiveRequestComplete(state) {
    state.unarchiveRequestPending = false;
  },

  performingArchiveRequest(state) {
    state.archiveRequestPending = true;
  },

  archiveRequestComplete(state) {
    state.archiveRequestPending = false;
  },

  performingInviteRequest(state) {
    state.inviteRequestPending = true;
  },

  inviteRequestComplete(state) {
    state.inviteRequestPending = false;
  },

  performingFetchEmployeeRequest(state) {
    state.loading = true;
  },

  performingFetchEmployeeComplete(state) {
    state.loading = false;
  },

  fetchEmployeeSuccess(state, payload) {
    state.employee = payload.data;
    state.currentUserVisibleNodes = payload.meta.current_user_visible_nodes.data
      .filter(node => node.attributes.configured);

    state.employee = matchAssociatedItem(state.employee, payload.included, 'contract', 'contract');
    state.employee = matchAssociatedItem(state.employee, payload.included, 'shop', 'shop');
    // TOFIX : find why we have to do this instead of matchAssociatedItem in order to have convention in relationship
    state.employee.relationships.convention = payload.included.find(record => record.type === 'convention');

    state.employee = matchAssociatedCollection(state.employee, payload.included, 'memberships', 'membership');
    state.employee = matchAssociatedCollection(state.employee, payload.included, 'teamMemberships', 'teamMembership');
    state.employee = matchAssociatedCollection(state.employee, payload.included, 'userLicenses', 'userLicense');
    state.employee = matchAssociatedCollection(state.employee, payload.included, 'automaticPlanningCompetencies', 'automaticPlanningCompetency');

    state.originalEmployeeData = cloneDeep(state.employee);
    state.originalEmployeeLicenseData = cloneDeep(state.employee.relationships.userLicenses);

    // Clean error flag (in case of previous fetch error)
    state.error = false;

    // if employee has variable hours -> initialize isTeamWithScheduleSelected to true
    state.employeeWithTeamScheduleSelected[state.employee.id] =
      payload.data.attributes.withVariableHours;
  },

  updateSelectedEmployeeSuccess(state, payload) {
    if (!payload.included) return;

    // If current user has switched to another employee while request was sending
    if (state.employee.id !== payload.data.id) return;

    state.employee.relationships.userLicenses = payload.included.filter(obj => obj.type === 'userLicense');
    state.employee.relationships.teamMemberships = payload.included.filter(obj => obj.type === 'teamMembership');

    const affiliatedShop = payload.included.find(obj => obj.type === 'shop');

    state.employee.relationships.shop =
      cloneDeep(affiliatedShop ?? state.employee.relationships.shop);
    state.originalEmployeeData = cloneDeep(state.employee);
    state.originalEmployeeLicenseData = cloneDeep(state.employee.relationships.userLicenses);
  },

  updateSelectedEmployeePayrollMissingAttributes(state, attributes) {
    if (attributes && store.getters['employees/displayPayrollPreparation']) {
      state.employee.attributes.payrollMissingAttributes = attributes;
    }
  },

  updateSelectedEmployeeContractSuccess(state, payload) {
    if (!payload.included) return;

    state.employee.relationships.contract = payload.included.find(obj => obj.type === 'contract');
  },

  updateInterimAndOnDayRate(state) {
    const contractData = store.state.config.config.contract_data;
    const contractAttr = state.employee.relationships.contract.attributes;

    state.employee.attributes.interim =
      contractData.contract_types
        .find(ct => ct.id === contractAttr.contractTypeId)
        .category === 'interim';

    state.employee.attributes.onDayRate =
      contractData.counter_type_daily === contractAttr.counterType;
  },

  archiveEmployeeSuccess(state, payload) {
    Object.assign(state.employee.attributes, payload.data.attributes);
  },

  unarchiveEmployeeSuccess(state, payload) {
    Object.assign(state.employee.attributes, payload.data.attributes);
  },

  catchEmployeeError(state, error) {
    state.error = error;
  },
  // set sticky bar disabled if one input is errored
  setInputEmployeeError(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.inputErrorAttributes[attribute] = payload[attribute];
    });

    state.inputError = some(Object.values(state.inputErrorAttributes));
  },

  resetPunchClockTokenSuccess(state, payload) {
    state.employee.attributes.punchClockToken = payload.attributes.punchClockToken;
  },

  reset(state) {
    Object.assign(state, initialState);
  },

  resetErrors(state) {
    state.error = false;
    state.inputError = false;
    state.inputErrorAttributes = {};
  },

  setEmployeeWithTeamScheduleSelected(state, { employeeId, value }) {
    state.employeeWithTeamScheduleSelected[employeeId] = value;
    state.employeeWithTeamScheduleSelected = { ...state.employeeWithTeamScheduleSelected };
  },

  performingRequest(state, key) {
    state[key] = true;
  },
  requestComplete(state, key) {
    state[key] = false;
  },

  catchAnnualizationError(state, payload) {
    state.errorsAnnualization = payload;
  },

  fetchEmployeeAnnualizationConfigSuccess(state, payload) {
    state.employeeAnnualizationConfig = payload;
    state.originalEmployeeAnnualizationConfig = cloneDeep(state.employeeAnnualizationConfig);
  },

  updateAnnualizationTotal(state, { periodTimestamp, newAnnualizationTotal }) {
    Vue.set(
      state.employeeAnnualizationConfig.theoreticalBalances,
      periodTimestamp,
      newAnnualizationTotal,
    );
  },

  resetOriginalEmployeeAnnualizationConfig(state) {
    state.originalEmployeeAnnualizationConfig = cloneDeep(state.employeeAnnualizationConfig);
  },

};

const actions = {
  async updateAmendmentAnnualizationData({ commit, state }, { amendment }) {
    const upsertAnnualizationParams = {
      contractHours: state.employeeAnnualizationConfig.contractHours,
      manualChanges: state.employeeAnnualizationConfig.manualChanges,
      proratedTheoreticalBalances: state.employeeAnnualizationConfig.proratedTheoreticalBalances,
      theoreticalBalances: state.employeeAnnualizationConfig.theoreticalBalances,
    };
    const amendmentStartsAt = skDate.utc(amendment.attributes.startsAt).valueOf();

    if (amendment.attributes.temporary) {
      const dayAfterAmendmentEndsAt = skDate.utc(amendment.attributes.endsAt).add(1, 'day').valueOf();

      delete upsertAnnualizationParams.contractHours[amendmentStartsAt];
      delete upsertAnnualizationParams.contractHours[dayAfterAmendmentEndsAt];
      delete upsertAnnualizationParams.proratedTheoreticalBalances[amendmentStartsAt];
      delete upsertAnnualizationParams.proratedTheoreticalBalances[dayAfterAmendmentEndsAt];
    } else {
      delete upsertAnnualizationParams.contractHours[amendmentStartsAt];
      delete upsertAnnualizationParams.proratedTheoreticalBalances[amendmentStartsAt];
      delete upsertAnnualizationParams.theoreticalBalances[amendmentStartsAt];
    }

    try {
      const response = await svcEmployeesClient.upsertAnnualizationConfig(
        state.employee.attributes.shopId,
        state.employee.id,
        upsertAnnualizationParams,
      );

      // Update the employee annualization config in the store
      commit('fetchEmployeeAnnualizationConfigSuccess', response);
    } catch (error) {
      commit('catchAnnualizationError', error.response);
    }
  },

  fetchEmployee({ commit }, params) {
    commit('performingFetchEmployeeRequest');
    commit('reset');

    return httpClient
      .get(`/v3/api/users/${params.id}`, { params })
      .then(response => {
        commit('fetchEmployeeSuccess', response.data);
      })
      .catch(error => {
        commit('catchEmployeeError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('performingFetchEmployeeComplete');
      });
  },

  updateSelectedEmployeePersonalInfo({ state, commit, getters }) {
    commit('performingUpdate');

    const currentOrganisation = store.state.currentOrganisation.currentOrganisation;

    const { clusters } = currentOrganisation.attributes;

    const formattedTeamMemberships = Object.values(
      state.employee.relationships.teamMemberships.reduce((acc, tm) => {
        acc[tm.attributes.teamId] = {
          id: tm.id,
          team_id: tm.attributes.teamId,
          shop_id: tm.attributes.shopId,
          _destroy: tm._destroy,
        };

        return acc;
      }, {}),
    );

    const employeeUserLicenses = state.employee.relationships.userLicenses;

    const promotingToSystemAdmin = employeeUserLicenses.length === 1 &&
      employeeUserLicenses[0].attributes.licensePosition === 0 &&
      !employeeUserLicenses[0].id;

    const demotingFromSystemAdmin =
      !employeeUserLicenses.map(ul => ul.attributes.licensePosition).includes(0) &&
      state.originalEmployeeData.relationships.userLicenses.map(
        ul => ul.attributes.licensePosition).includes(0);

    let formattedUserLicenses;
    if (promotingToSystemAdmin) {
      const licenseIds = employeeUserLicenses.map(ul => ul.attributes.licenseId);
      formattedUserLicenses = [{
        id: null,
        license_id: uniq(licenseIds)[0],
        cluster_node_id: currentOrganisation.attributes.rootNodeId,
      }];
    } else {
      formattedUserLicenses = employeeUserLicenses.map(ul => ({
        id: ul.id,
        cluster_node_id: ul.attributes.clusterNodeId,
        license_id: ul.attributes.licenseId,
        _destroy: ul._destroy,
      }));
    }

    const { automaticPlanningCompetencies } = state.employee.relationships;
    const formattedCompetencies = automaticPlanningCompetencies.map(
      ({ attributes }) => ({
        primary: !!attributes.primary,
        shop_id: attributes.shopId,
        poste_id: attributes.posteId,
      }),
    );

    const params = {
      personal_info: {
        first_name: state.employee.attributes.firstName,
        last_name: state.employee.attributes.lastName,
        birth_name: state.employee.attributes.birthName,
        email: state.employee.attributes.email,
        phone: state.employee.attributes.phone,
        pay_identification_number: state.employee.attributes.payIdentificationNumber,
        shop_id: state.employee.attributes.shopId,
        user_extended_info: {
          in_staff_register: state.employee.attributes.inStaffRegister,
        },
      },
      associations: {
        user_licenses: formattedUserLicenses,
        team_memberships: formattedTeamMemberships,
        automatic_planning_competencies: formattedCompetencies,
      },
      toggling_system_admin_status: (!clusters && demotingFromSystemAdmin) ||
        promotingToSystemAdmin,
      incomplete_profiles: store.getters['employees/displayPayrollPreparation'],
      with_shop_extended_info: getters.isAutomaticDpaeEnabled,
    };

    return httpClient.patch(
      `/v3/api/users/${state.employee.id}/personal_info`,
      params,
    )
      .then(response => (
        Promise.all(getters.promisesMemberships)
          .then(responseMembership => {
            commit('updateMemberships', responseMembership.map(rm => rm.data.data));
            commit('updateSelectedEmployeeSuccess', response.data);
            commit('updateSelectedEmployeePayrollMissingAttributes', response.data.data.attributes.payrollMissingAttributes);
            commit('squashOriginalEmployee');
            store.commit('employees/replaceEmployee', state.employee);
          })
          .catch(error => {
            commit('catchEmployeeError', error.response.data);
            throw error;
          })
          .finally(() => {
            commit('performingUpdateComplete');
          })
      ))
      .catch(error => {
        commit('catchEmployeeError', error.response.data);
        throw error;
      });
  },

  updateSelectedEmployeeContract({ state, commit }) {
    commit('performingUpdate');

    const contract = state.employee.relationships.contract.attributes;
    const params = {
      contract: {
        poste_name: contract.posteName,
        dpae_status: contract.dpaeDepositCompleted,
        salary_calculation_type: contract.salaryCalculationType,
        level: contract.level,
        step: contract.step,
        status: contract.status,
        tutor: contract.tutor,
        contract_hours: contract.contractHours,
        contract_type_id: contract.contractTypeId,
        time_basis_category: contract.timeBasisCategory,
        fixed_term_contract_reason: contract.fixedTermContractReason,
        work_days: contract.workDays,
        counter_type: contract.counterType,
        start_date: contract.startDate,
        trial_end: contract.trialEnd,
        end_date: contract.endDate,
        monthly_salary: contract.monthlySalary,
        hourly_wage: contract.hourlyWage,
        hourly_wage_with_costs: contract.hourlyWageWithCosts,
        transport_cost: contract.transportCost,
      },
      incomplete_profiles: store.getters['employees/displayPayrollPreparation'],
    };

    return httpClient
      .patch(`/v3/api/users/${state.employee.id}/legacy_contract`, params)
      .then(response => {
        commit('updateSelectedEmployeeContractSuccess', response.data);
        commit('updateInterimAndOnDayRate', response.data);
        commit('squashOriginalEmployee');
        store.commit('employees/replaceEmployee', state.employee);
      })
      .finally(() => {
        commit('performingUpdateComplete');
      })
      .catch(error => {
        commit('catchEmployeeError', error.response.data);
        throw error;
      });
  },

  updateSelectedEmployeeHr({ state, commit, getters }) {
    commit('performingUpdate');

    const params = {
      birthday: state.employee.attributes.birthday,
      nationality: state.employee.attributes.nationality,
      birth_place: state.employee.attributes.birthPlace,
      birth_department: state.employee.attributes.birthDepartment,
      country_code_of_birth: state.employee.attributes.countryCodeOfBirth,
      gender: state.employee.attributes.gender,
      address: state.employee.attributes.address,
      address_details: state.employee.attributes.addressDetails,
      postal_code: state.employee.attributes.postalCode,
      city: state.employee.attributes.city,
      country_code_of_residence: state.employee.attributes.countryCodeOfResidence,
      payment_method: state.employee.attributes.paymentMethod,
      bank_info: state.employee.attributes.bankInfo,
      swift_code: state.employee.attributes.swiftCode,
      comments: state.employee.attributes.comments,
      social_security_number: state.employee.attributes.socialSecurityNumber,
      number_of_dependents: state.employee.attributes.numberOfDependents,
      document_type: state.employee.attributes.documentType,
      expiration_date_document: state.employee.attributes.expirationDateDocument,
      identity_number: state.employee.attributes.identityNumber,
      is_missing_social_security_number: state.employee.attributes.isMissingSocialSecurityNumber,
      last_medical_exam: state.employee.attributes.lastMedicalExam,
      incomplete_profiles: store.getters['employees/displayPayrollPreparation'],
      with_shop_extended_info: getters.isAutomaticDpaeEnabled,
    };

    if (store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_UPGRADE_MULTICONTRACTS')) {
      params.arrival_date = state.employee.attributes.arrivalDate;
    }

    return httpClient
      .patch(`/v3/api/users/${state.employee.id}/hr`, params)
      .then(response => {
        commit('updateSelectedEmployeeSuccess', response.data);
        commit('updateSelectedEmployeePayrollMissingAttributes', response.data.data.attributes.payrollMissingAttributes);
        commit('squashOriginalEmployee');
        store.commit('employees/replaceEmployee', state.employee);
      })
      .finally(() => {
        commit('performingUpdateComplete');
      })
      .catch(error => {
        commit('catchEmployeeError', error.response.data);
        throw error;
      });
  },

  archiveEmployee({ commit }, params) {
    commit('performingArchiveRequest');

    return httpClient
      .patch(`/v3/api/users/${params.employee_id}/archive`, params)
      .then(response => {
        commit('archiveEmployeeSuccess', response.data);
      })
      .catch(error => {
        commit('catchEmployeeError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('archiveRequestComplete');
      });
  },

  unarchiveEmployee({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingUnarchiveRequest');

      httpClient
        .patch(`/v3/api/users/${params.employee_id}/unarchive`, { employee_id: params.employee_id })
        .then(response => {
          commit('unarchiveEmployeeSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchEmployeeError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('unarchiveRequestComplete');
        });
    });
  },

  inviteEmployee({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingInviteRequest');

      httpClient
        .post(`/v3/api/users/${params.employee_id}/invite`, { employee_id: params.employee_id })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit('catchEmployeeError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('inviteRequestComplete');
        });
    });
  },

  resetPunchClockToken({ commit }, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .patch(`/v3/api/users/${params.employee_id}/reset_pin`, { employee_id: params.employee_id })
        .then(response => {
          commit('resetPunchClockTokenSuccess', response.data.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchEmployeeError', error.response.data);
          reject(error);
        });
    });
  },

  sendPunchClockToken({ commit }, params) {
    return httpClient
      .patch(`/v3/api/users/${params.employee_id}/send_pin`, {
        send_by_sms: params.send_by_sms,
        send_by_email: params.send_by_email,
        employee_id: params.employee_id,
      })
      .then(response => response.data)
      .catch(error => {
        commit('catchEmployeeError', error.response.data.data);
        throw error;
      });
  },

  resetToOriginalState({ commit }) {
    commit('squashChangesToEmployee');
    commit('resetErrors');
  },

  async fetchEmployeeAnnualizationConfig({ commit }, params) {
    commit('performingRequest', 'areEmployeeAnnualizationConfigsLoading');

    try {
      const response =
        await svcEmployeesClient.findOneByShopIdUserId(params.shopId, params.userId);

      commit('fetchEmployeeAnnualizationConfigSuccess', response);
    } catch (error) {
      commit('catchAnnualizationError', error.response);
      throw error;
    } finally {
      commit('requestComplete', 'areEmployeeAnnualizationConfigsLoading');
    }
  },

  async updateEmployeeAnnualizationConfig({ commit }, params) {
    commit('performingRequest', 'areEmployeeAnnualizationConfigsUpdateLoading');
    try {
      await svcEmployeesClient.updateAnnualizationConfig(
        params.shopId,
        params.userId,
        { theoreticalBalances: params.theoreticalBalances },
      );
    } catch (error) {
      commit('catchAnnualizationError', error.response.data);
      throw error;
    } finally {
      commit('requestComplete', 'areEmployeeAnnualizationConfigsUpdateLoading');
    }
  },
};

const getters = {
  getNextTabIfComplete: (_state, selfGetters, _rootState, rootGetters) => tab => {
    if (!selfGetters.hasMissingMandatoryFieldsForPayroll(tab) && rootGetters['employees/canAccessPayrollEmployee']) {
      return selfGetters.getNextTabWithMissingInfo;
    }
    return null;
  },
  getNextTabWithMissingInfo: (_state, selfGetters) => Object
    .keys(selfGetters.getMandatoryAttributesForPayroll)
    .find(tab => selfGetters.hasMissingMandatoryFieldsForPayroll(tab)),
  isIntern: (_state, _selfGetters, rootState) => contractTypeId => {
    if (!contractTypeId) return false;

    return rootState.config.config.contract_data.contract_types
      .find(ct => ct.id === contractTypeId)
      .category === 'intern';
  },
  getMandatoryAttributesForPayroll: (state, selfGetters, _rootState, rootGetters) => {
    // Check if employee is loaded.
    if (Object.keys(state.employee.attributes).length === 0) return MANDATORY_ATTRIBUTE_TO_TAB;
    const mandatoryAttributes = cloneDeep(MANDATORY_ATTRIBUTE_TO_TAB);

    const employee = state.employee;
    const shop = employee.relationships.shop;
    const contract = employee.relationships.contract;

    if (employee.attributes.nationality !== shop.attributes.country) {
      mandatoryAttributes.user_hr_file.push('documentType', 'expirationDateDocument');
    }

    if (!employee.attributes.isMissingSocialSecurityNumber) {
      mandatoryAttributes.user_hr_file.push('socialSecurityNumber');
    }
    if (rootGetters['currentShop/checkFeatureFlag']('FEATURE_ID_NUMBER_MANDATORY_FOR_PAYROLL') &&
        rootGetters['currentShop/checkFeatureFlag']('FEATURE_ID_NUMBER')) {
      mandatoryAttributes.user_hr_file.push('identityNumber');
    }
    mandatoryAttributes.user_contracts.push(selfGetters.onDayRate ? 'workDays' : 'contractHours');
    if (rootGetters['contracts/isContractEndDateOptional'](contract) === false) {
      mandatoryAttributes.user_contracts.push('endDate');
    }
    if (selfGetters.isIntern(contract.attributes?.contractTypeId)) {
      mandatoryAttributes.user_contracts.push('tutor');
    }
    if (shop.attributes.isFrenchShop) {
      mandatoryAttributes.user_contracts.push('pcsEse');
      mandatoryAttributes.user_contracts.push('dpaeDepositCompleted');
    }
    const { convention } = state.employee.relationships;
    if (convention.attributes.contractData) {
      mandatoryAttributes.user_contracts.push('level');
      mandatoryAttributes.user_contracts.push('step');
    }
    // Do not check properties on contract if its not active
    if (!rootGetters['contracts/isActiveContract'](contract)) {
      mandatoryAttributes.user_contracts = [];
    }

    return mandatoryAttributes;
  },
  hasMissingMandatoryFieldsForPayroll: (state, selfGetters, rootState) => tabName => {
    const mandatoryAttributes = selfGetters.getMandatoryAttributesForPayroll;
    if (!Object.keys(mandatoryAttributes).includes(tabName)) throw Error(`Unknown tab ${tabName}`);

    let model;
    if (tabName === 'user_contracts') {
      const contract = rootState.contracts.contract ?? state.employee.relationships.contract;

      model = {
        ...contract.attributes,
        arrivalDate: state.employee.attributes.arrivalDate,
      };
    } else model = state.employee.attributes;

    return mandatoryAttributes[tabName]
      .some(attribute => !model[attribute] && model[attribute] !== 0);
  },
  isAutomaticDpaeEnabled: state => state.employee.relationships.shop.attributes.isFrenchShop &&
    store.state.currentOrganisation?.currentOrganisation?.attributes?.packOffer?.name === 'premium',
  getMandatoryAttributesForDpae: () => cloneDeep(MANDATORY_ATTRIBUTES_FOR_DPAE),
  missingMandatoryFieldsForDpae: (state, selfGetters, rootState) => {
    const mandatoryAttributes = selfGetters.getMandatoryAttributesForDpae;

    // The two stores 'contracts' and 'selected-employees' are used on the contract page
    // So we need to check if the contract is loaded from both stores (like for hasMissingMandatoryFieldsForPayroll)
    const contract = rootState.contracts.contract.attributes;
    const contractAttributes = contract ?? state.employee.relationships.contract.attributes;

    const model = {
      shop: state.employee.relationships.shop.attributes,
      employee: state.employee.attributes,
      contract: contractAttributes,
    };

    return Object.keys(mandatoryAttributes)
      .map(key => mandatoryAttributes[key].filter(
        attribute => !model[key][attribute] && model[key][attribute] !== 0,
      )).flat();
  },
  employeeIsLoaded: state => state.employee.id !== '' && !state.loading,
  initials: state => {
    if (state.employee.attributes.firstName && !state.employee.attributes.lastName) {
      return state.employee.attributes.firstName[0].toUpperCase();
    }

    if (!state.employee.attributes.firstName && state.employee.attributes.lastName) {
      return state.employee.attributes.lastName[0].toUpperCase();
    }

    return state.employee.attributes.firstName[0].toUpperCase() +
      state.employee.attributes.lastName[0].toUpperCase();
  },
  fullName: state => `${capitalize(state.employee.attributes.firstName)} ${
    capitalize(state.employee.attributes.lastName)}`,
  isInManyShopsPlanning: state => (state.employee.attributes.planningShopsCount > 1),
  stringifiedArchivedAt: state => {
    if (state.employee.attributes.archivedAt === null) return null;

    return skDate(state.employee.attributes.archivedAt).format('dddd LL');
  },
  license: state => {
    const { currentShop } = store.state.currentShop;
    let license = null;

    if (currentShop.id !== 'all') {
      // Find license for selected shop
      license = state.employee.relationships.userLicenses.find(userLicense => (
        String(userLicense.attributes.shopId) === currentShop.id ||
          (userLicense.attributes.descendantIds && userLicense.attributes.descendantIds.includes(
            Number(currentShop.relationships.clusterNode.id),
          ))
      ));
    }

    // Otherwise, fallback on highest license available
    if (!license) {
      license = first(orderBy(state.employee.relationships.userLicenses, 'attributes.licensePosition'));
    }

    return license;
  },
  activeUserLicenses: (state, selfGetters) => {
    // product edge case
    if (selfGetters.isSystemAdmin &&
        !store.state.currentOrganisation.currentOrganisation.attributes.clusters) {
      return selfGetters.activeNodes.map(node => ({
        id: Number(node.id),
        type: 'userLicense',
        attributes: {
          clusterNodeId: Number(node.id),
          shopId: Number(node.attributes.shopId),
          name: node.attributes.name,
          userId: state.employee.id,
          licenseId: state.employee.relationships.userLicenses[0].attributes.licenseId,
          licenseName: state.employee.relationships.userLicenses[0].attributes.licenseName,
          depth: 0,
        },
      }));
    }

    return state.employee.relationships.userLicenses.filter(userLicense => (
      !userLicense._destroy
    ));
  },
  primaryShopNode(state) {
    return state.currentUserVisibleNodes.find(clusterNode => (
      String(clusterNode.attributes.shopId) === String(state.employee.attributes.shopId)
    ));
  },
  activeNodes(state) {
    const shopNodes = state.currentUserVisibleNodes.filter(clusterNode => (
      clusterNode.attributes.shopId !== null
    ));

    return orderBy(shopNodes, 'attributes.name');
  },
  unsavedChangesToEmployee(state) {
    const originalEmployeeState = cloneDeep(state.originalEmployeeData);

    // Exclude these fields from comparison (not managed by sticky bar)
    originalEmployeeState.attributes.archivedAt = state.employee.attributes.archivedAt;
    originalEmployeeState.attributes.avatarUrl = state.employee.attributes.avatarUrl;
    originalEmployeeState.attributes.archiveNote = state.employee.attributes.archiveNote;
    originalEmployeeState.attributes.punchClockToken = state.employee.attributes.punchClockToken;
    originalEmployeeState.attributes.plcInitializationDoneAt =
      state.employee.attributes.plcInitializationDoneAt;
    originalEmployeeState.attributes.hoursCounterInitialized =
      state.employee.attributes.hoursCounterInitialized;

    // Special case for birthName: we do not show the sticky bar if the birthName went from filled to empty
    if (state.employee.attributes.birthName === '') {
      originalEmployeeState.attributes.birthName = '';
    }

    return JSON.stringify(state.employee) !== JSON.stringify(originalEmployeeState);
  },

  unsavedChangesToEmployeeLicense(state) {
    return JSON.stringify(state.employee.relationships.userLicenses) !==
      JSON.stringify(state.originalEmployeeLicenseData);
  },

  isSystemAdmin(state) {
    return state.employee.relationships.userLicenses[0].attributes.licensePosition === 0;
  },
  isCurrentUserOrSystemAdmin(state, selfGetters) {
    return selfGetters.isCurrentUser ||
      store.state.currentLicense.currentLicense.attributes.position === 0;
  },
  isCurrentlyArchived(state) {
    if (!state.employee.attributes.archivedAt) return false;

    return skDate(state.employee.attributes.archivedAt).endOf('day') < skDate().endOf('day');
  },
  hasFutureArchivalDate(state) {
    if (!state.employee.attributes.archivedAt) return false;

    return skDate(state.employee.attributes.archivedAt).endOf('day') >= skDate().endOf('day');
  },
  hasPaidLeavesCounter: state => !!state.employee.attributes.plcInitializationDoneAt,
  isCurrentUser(state) {
    return state.employee.id === store.state.currentUser.currentUser.id;
  },
  isOnSameTeamThanCurrentUser(state) {
    return intersectionWith(state.employee.relationships.teams.data,
      store.state.currentUser.currentUser.relationships.teams.data,
      isEqual).length > 0;
  },
  isStrictSubordinateOfCurrentUser(state) {
    return state.employee.attributes.currentLicensePosition >
    store.state.currentLicense.currentLicense.attributes.position;
  },
  isEqualToOrSubordinateOfCurrentUser(state) {
    return state.employee.attributes.currentLicensePosition >=
    store.state.currentLicense.currentLicense.attributes.position;
  },
  currentUserHasPermission(_state, selfGetters) {
    if (selfGetters.isCurrentUserOrSystemAdmin ||
        selfGetters.isEqualToOrSubordinateOfCurrentUser) {
      return true;
    }
    return false;
  },
  hasChangesOnPersonalInfo(_state, selfGetters) {
    if (!selfGetters.currentUserHasPermission) return false;
    const licenseAttributes = store.state.currentLicense.currentLicense.attributes;
    return selfGetters.unsavedChangesToEmployee &&
           licenseAttributes.canEditEmployeeInfo;
  },
  hasChangesOnHrFile(_state, selfGetters) {
    if (!selfGetters.currentUserHasPermission) return false;
    const licenseAttributes = store.state.currentLicense.currentLicense.attributes;
    return selfGetters.unsavedChangesToEmployee && (
      licenseAttributes.canEditEmployeeInfo ||
           licenseAttributes.canEditEmployeePersonalInfosAndBankData ||
           licenseAttributes.canEditWageAndTime
    );
  },
  isClustersOrganisation(_state) {
    return store.state.currentOrganisation.currentOrganisation.attributes.clusters;
  },
  isInterim(state) {
    if (!state.employee.relationships.contract.attributes.contractTypeId) return false;

    return store.state.config.config.contract_data.contract_types
      .find(ct => ct.id === state.employee.relationships.contract.attributes.contractTypeId)
      .category === 'interim';
  },
  onDayRate(state) {
    return store.state.config.config.contract_data.counter_type_daily ===
      state.employee.relationships.contract.attributes.counterType;
  },
  isCurrentUserOrStrictSubordinate(_state, selfGetters) {
    return selfGetters.isCurrentUserOrSystemAdmin || selfGetters.isStrictSubordinateOfCurrentUser;
  },
  promisesMemberships(state) {
    return state.employee.relationships.memberships.map(membership => {
      const paramsMembership = {
        membership: {
          in_planning: membership.attributes.inPlanning,
          shop_id: Number(membership.attributes.shopId),
          user_id: Number(state.employee.id),
        },
      };

      if (membership._destroy &&
        String(state.employee.attributes.shopId) !== String(membership.attributes.shopId)
      ) {
        return httpClient.delete(`/v3/api/memberships/${membership.id}`);
      }

      if (membership.id) {
        return httpClient.patch(`/v3/api/memberships/${membership.id}`, paramsMembership);
      }

      return httpClient.post('/v3/api/memberships', paramsMembership);
    });
  },
  hasTeamWithScheduleSelectedFor: state => id => (
    state.employeeWithTeamScheduleSelected[id]
  ),
  hasAnnualizationTotalChanged(state) {
    if (!state.employeeAnnualizationConfig) {
      return false;
    }

    return !isEqual(
      state.employeeAnnualizationConfig.theoreticalBalances,
      state.originalEmployeeAnnualizationConfig.theoreticalBalances,
    );
  },
  principalShop: state => state.employee.relationships.shop,
};
/* eslint-enable no-shadow */

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
  getters,
};
