import takeRight from 'lodash/takeRight';
import sumBy from 'lodash/sumBy';

import skDate from '@skello-utils/dates';
import { isEmpty } from '@skello-utils/array';
import BadgingAnomalyDetector from './BadgingAnomalyDetector';

export default class MatchedBadging {
  constructor(
    badging,
    shift,
    badgingHistories,
    user,
    currentUser,
    shop,
    settings,
    userBadgingsPage,
    currentDate,
  ) {
    this.user = user;
    this.badging = badging;
    this.shop = shop;
    this.shift = shift;
    this.currentUser = currentUser;
    this.currentDate = currentDate;
    this.settings = settings;
    this.userBadgingsPage = userBadgingsPage;
    this.key = `${shift?.id}-${badging?.id}`;
    this.shiftId = shift?.id || null;
    this.isGrouped = shift?.type === 'groupedShifts';
    this.badgingId = badging?.id ? badging.id : null;
    this.userId = badging?.user_id ? badging.user_id : shift?.attributes.userId;
    this.userShopName = shop.attributes.name;
    this.firstName = user.attributes.firstName;
    this.lastName = user.attributes.lastName;
    const date = shift?.attributes?.startsAt || badging?.in;
    const dateStart = skDate(date).utc().subtract(1, 'd').format('YYYY-MM-DD');
    const dateEnd = skDate(date).utc().add(1, 'd').format('YYYY-MM-DD');
    this.badgingHistories = badgingHistories.filter(
      bH => bH.date >= dateStart && bH.date <= dateEnd,
    ) || [];
    this.ignored = badging?.ignored;
    this.validated = this.isBadgingValidated();
    this.predictedStartsAt =
      shift?.attributes.previsionalStart || shift?.attributes.startsAt || null;
    this.predictedEndsAt =
      shift?.attributes.previsionalEnd || shift?.attributes.endsAt || null;
    this.predictedBreak = this.predictedBreak(shift) || null;
    this.meal = shift?.attributes.nbMeal || null;
    this.note = shift?.attributes.note || null;
    this.isAbsence = shift ? shift.attributes.absenceCalculation !== '' : false;
    this.hasBeenIgnored = badging?.ignored;
    this.posteId = !this.isAbsence ? shift?.relationships?.poste?.id : null;
    this.absencePosteId = this.isAbsence ? shift?.relationships?.poste?.id : null;
    this.posteName = shift?.relationships?.poste?.attributes?.name || null;
    this.posteColor = shift?.relationships?.poste?.attributes?.color || null;
    this.absencePosteName = this.isAbsence ? this.posteName : null;
    this.previsionalPosteName = shift?.relationships.previsionalPoste?.attributes?.name || null;
    this.previsionalPosteIsAbsence =
      !!shift?.relationships.previsionalPoste?.attributes?.absenceKey;
    this.absenceCalculation = shift?.attributes?.absenceCalculation;
    this.badgingStartsAt = badging?.in;
    this.badgingEndsAt = badging?.out;
    this.badgingBreaks = badging?.pauses && badging.pauses.length > 0 ? badging?.pauses : [];
    this.selectedStartsAt = this.selectedStartsAt();
    this.selectedEndsAt = this.selectedEndsAt();
    this.selectedBreak = this.selectedBreakTime();
    this.turnedAsAbsence = this.isTurnedAsAbsence();
    this.isUserShopHasNoBadging =
      badging?.shop_id !== user.attributes.shopId &&
      !user.attributes.userShopHasPunchClock;
    this.skipPrevisional = false;
    this.workDuration = this.workDuration();
    this.closedByBackend = badging?.closed_by_backend;
    this.anomalyReason = this.getAnomalyReason();
    const badgingValidationData = this.getBadgingValidationData();
    this.validatedBy = badgingValidationData.validatedBy;
    this.validatedAt = badgingValidationData.validatedAt;
  }

  lastValidatedBadgings() {
    const userBadgingsHistory = this.userBadgingsHistory();
    if (!userBadgingsHistory || userBadgingsHistory.length === 0) {
      return [];
    }

    if (this.isShiftIncludedInBadgingHistory(userBadgingsHistory)) {
      if (this.isGrouped) {
        const ids = this.getShiftIdsByFilter(
          shift => shift.attributes.previsionalSaved && shift.attributes.startsAt,
        );

        return takeRight(userBadgingsHistory.filter(
          badgingHistory => ids.includes(String(badgingHistory?.shift_id)),
        ), ids.length);
      }
      return userBadgingsHistory
        .filter(
          badgingHistory => parseInt(badgingHistory?.shift_id, 10) === parseInt(this.shift.id, 10),
        );
    }

    return userBadgingsHistory.filter(
      badgingHistory => parseInt(badgingHistory?.id, 10) === parseInt(this.badging?.id, 10),
    );
  }

  /**
   * When a shift is grouped, this shift has this form:
   * shift : { shifts: [shift1, shift2...], type: 'GroupedShift' }
   *
   * When it's not it is a normal shift.
   */
  isShiftIncludedInBadgingHistory(userBadgingsHistory) {
    if (!this.shift) return false;

    const userBadgingsHistoryShiftIds = userBadgingsHistory
      .map(badgingHistory => String(badgingHistory.shift_id));

    if (this.isGrouped) {
      const shiftsIds = this.shift.shifts.map(shift => String(shift.id));

      return userBadgingsHistoryShiftIds.some(shiftId => shiftsIds.includes(shiftId));
    }

    return userBadgingsHistoryShiftIds.includes(String(this.shift.id));
  }

  getLastValidatedBadging() {
    const lastValidatedBadgings = this.lastValidatedBadgings();
    return lastValidatedBadgings[lastValidatedBadgings.length - 1];
  }

  getFirstValidatedBadging() {
    return this.lastValidatedBadgings()[0];
  }

  predictedBreak(shift) {
    const predictedShiftBreak = this.getLastValidatedBadging()?.shift_pause;
    const userBadgingsHistory = this.userBadgingsHistory();

    if (this.isBadgingValidated() && (predictedShiftBreak || predictedShiftBreak === 0)) {
      if (this.isGrouped) {
        const ids = this.getShiftIdsByFilter(
          shiftParam => shiftParam.attributes.previsionalSaved || shiftParam.attributes.startsAt,
        );

        const isShiftInHistory = userBadgingsHistory
          .filter(badgingHistory => ids.includes(String(badgingHistory?.shift_id)))
          .slice(0, ids.length);

        const pauseTime = sumBy(isShiftInHistory, shiftHistory => shiftHistory.shift_pause);

        return pauseTime * 60;
      }

      return predictedShiftBreak * 60;
    }
    return shift?.attributes.pauseTime;
  }

  selectedBreakTime() {
    if (this.isBadgingValidated()) {
      const userBadgingsHistory = this.userBadgingsHistory();

      if (this.isGrouped) {
        const ids = this.getShiftIdsByFilter(
          shift => shift.attributes.previsionalSaved || shift.attributes.startsAt,
        );
        const isShiftInHistory = userBadgingsHistory
          .filter(badgingHistory => ids.includes(String(badgingHistory?.shift_id)))
          .slice(ids.length * -1);

        return sumBy(
          isShiftInHistory,
          shiftHistory => (shiftHistory.choice_pause ? shiftHistory.choice_pause : 0),
        );
      }

      return this.getLastValidatedBadging()?.choice_pause || 0;
    }

    if (this.areBreaksFullyBadged()) {
      return this.badging.pauses.reduce((accumulator, pauseStartAndEnd) => {
        const pauseStart = skDate(pauseStartAndEnd[0]).valueOf();
        const pauseEnd = skDate(pauseStartAndEnd[1]).valueOf();

        return accumulator + Math.round((pauseEnd - pauseStart) / 60000); // ms -> min
      }, 0);
    }

    if (
      !this.isMatchingCompleted() ||
      this.isShiftWithoutCompleteBadge() ||
      !this.shift.attributes.pauseTime
    ) {
      return 0;
    }

    return this.shift.attributes.pauseTime / 60;
  }

  selectedStartsAt() {
    if (this.isBadgingValidated() && !this.ignored) {
      return this.isGrouped ?
        this.getFirstValidatedBadging().choice_start :
        this.getLastValidatedBadging().choice_start;
    }
    if (!this.badging) {
      // the check this.isAbsence is an edge case and normally shouldnt happen...
      // couldn't find why absences are shown when they shoulnt
      if (!this.shift.attributes.startsAt ||
        this.isAbsence ||
        skDate.utc(this.shift.attributes.startsAt) >= skDate.utc()) return null;
      return skDate.utc(this.shift.attributes.startsAt).format('HH:mm');
    }
    let date;
    if (!this.shift) {
      date = this.badging.in;
    } else if (skDate(this.badging.in).isBefore(this.shift.attributes.startsAt)) {
      date = this.settings.earlyArrivalTakesPlannedDate ?
        this.shift.attributes.startsAt : this.badging.in;
    } else {
      date = this.settings.lateArrivalTakesPlannedDate ?
        this.shift.attributes.startsAt : this.badging.in;
    }

    return skDate(date).isValid() ? skDate(date).utc().format('HH:mm') : null;
  }

  selectedEndsAt() {
    if (this.isBadgingValidated() && !this.ignored) {
      return this.getLastValidatedBadging().choice_end;
    }
    if (!this.badging) {
      // the check this.isAbsence is an edge case and normally shouldnt happen...
      // couldn't find why absences are shown when they shoulnt
      if (!this.shift.attributes.endsAt ||
        this.isAbsence ||
        skDate.utc(this.shift.attributes.endsAt) >= skDate.utc()) return null;
      return skDate.utc(this.shift.attributes.endsAt).format('HH:mm');
    }
    let date;
    if (!this.shift) {
      date = this.badging.out;
    } else if (skDate(this.badging.out).isBefore(this.shift.attributes.endsAt)) {
      date = this.settings.earlyDepartureCountedAsEarly ?
        this.badging.out : this.shift.attributes.endsAt;
    } else {
      date = this.settings.lateDepartureCountedAsLate ?
        this.badging.out : this.shift.attributes.endsAt;
    }

    return skDate(date).isValid() ? skDate(date).utc().format('HH:mm') : null;
  }

  workDuration() {
    if (!this.selectedStartsAt || !this.selectedEndsAt) return 0;
    if (this.selectedStartsAt === this.selectedEndsAt) return 24 * 3600; // 24 hours

    const date = skDate.utc(this.predictedStartsAt || this.badgingStartsAt).format('YYYY-MM-DD');

    const selectedStartsAt = skDate(`${date} ${this.selectedStartsAt}`);
    const selectedEndsAt = skDate(`${date} ${this.selectedEndsAt}`);

    const { pauseCompensationStartsAt } = this.shop.attributes;

    if (selectedEndsAt.isBefore(selectedStartsAt)) selectedEndsAt.add(1, 'days');

    let duration = skDate.duration(
      selectedEndsAt
        .diff(selectedStartsAt),
    ).asSeconds();

    if (!pauseCompensationStartsAt && !this.turnedAsAbsence) {
      duration -= this.selectedBreak * 60; // selectedBreak as seconds
    }
    return duration;
  }

  isBadgingValidated() {
    if (this.ignored) return true;
    if (this.userBadgingsHistory().length === 0) return false;
    const lastValidatedBadging = this.getLastValidatedBadging();

    if (!lastValidatedBadging || lastValidatedBadging?.length === 0) return false;

    return 'choice_start' in lastValidatedBadging && 'choice_end' in lastValidatedBadging;
  }

  userBadgingsHistory() {
    return this.badgingHistories?.flatMap(badgingHistory => badgingHistory.badgings
      .filter(badging => parseInt(badging.user_id, 10) === parseInt(this.user.id, 10)));
  }

  dailyBadgingsHistory() {
    const predictedStartsAt = skDate(this.predictedStartsAt)?.utc().format('YYYY-MM-DD');
    return this.badgingHistories
      .filter(badgingHistory => badgingHistory.date === predictedStartsAt)
      .flatMap(badgingHistory => badgingHistory.badgings
        .filter(badging => parseInt(badging.user_id, 10) === parseInt(this.user.id, 10)));
  }

  isMatchingCompleted() {
    return this.badging?.in &&
      this.badging?.out &&
      this.shift?.attributes.startsAt &&
      this.shift?.attributes.endsAt;
  }

  isAnyBreakTaken() {
    return !isEmpty(this.badging?.pauses);
  }

  areBreaksFullyBadged() {
    if (!this.isAnyBreakTaken()) return false;

    return !this.badging.pauses.some(
      pauseStartAndEnd => !pauseStartAndEnd[0] || !pauseStartAndEnd[1],
    );
  }

  isShiftWithoutCompleteBadge() {
    return this.shift?.id && (!this.badging.out || !this.badging.in);
  }

  isTurnedAsAbsence() {
    return (
      this.isBadgingValidated() &&
      this.isAbsence &&
      !this.areBothAbsencesEqual()
    );
  }

  // this method is to handle the edge case where the user does the following
  // he badges a shift that is an absence
  // it is turned into an absence
  // it is turned back into it's original poste
  // if both absences are equal, then it was not turned into an absence.
  areBothAbsencesEqual() {
    return this.shift?.relationships.previsionalPoste?.id === this.absencePosteId;
  }

  getShiftIdsByFilter(callback) {
    return this.shift
      .shifts
      .filter(callback)
      .map(shift => String(shift.id));
  }

  getAnomalyReason() {
    const badgingAnomalyDetector = new BadgingAnomalyDetector(this);

    return badgingAnomalyDetector.getAnomalyReason();
  }

  getBadgingValidationData() {
    const lastValidatedBadging = this.getLastValidatedBadging();
    const validatedBadgingHistory = this.badgingHistories
      .find(badgingHistory => badgingHistory.badgings
        .find(badging => badging.id === lastValidatedBadging?.id));

    if (!this.validated || !validatedBadgingHistory) {
      return {
        validatedBy: null,
        validatedAt: null,
      };
    }

    return {
      validatedBy: validatedBadgingHistory.validated_by,
      validatedAt: validatedBadgingHistory.validated_at,
    };
  }
}
